import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    zbozi: [],
    heureka: [],
  },
  mutations: {
    SET_ZBOZI(state, items) {
      state.zbozi = items.data
    },
    SET_HEUREKA(state, items) {
      state.heureka = items.data
    },
  },
  actions: {
    listHeureka({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/delivery-dictionary/heureka', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_HEUREKA', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    listZbozi({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/delivery-dictionary/zbozi', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ZBOZI', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    listZbozi: state => state.zbozi,
    listHeureka: state => state.heureka,
  },
}
