import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'id',
        type: 'int',
      }, {
        key: 'name',
        type: 'string',
      }, {
        key: 'updated_at',
        type: 'date',
      }, {
        key: 'url',
        type: 'string',
      }, {
        key: 'is_active',
        type: 'bool',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
    UPDATE(state, data) {
      state.items.forEach(x => {
        if (x.id === data.id) {
          const keys = Object.keys(data.item)
          keys.forEach(y => {
            x[y] = data.item[y]
          })
        }
      })
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/feeds', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/feeds/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item, from }) {
      return new Promise((resolve, reject) => {
        $axios.put('/feeds/' + id, item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          if (from === 'LIST') {
            commit('UPDATE', { id: id, item: item })
          }
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => (searchText = '') => {
      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(state.items, filteredKeys, searchText)
      }
      return state.items
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
