import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: 'languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'ble_name',
        type: 'string',
      }, {
        key: 'ble_is_active',
        type: 'bool',
      }, {
        key: 'updated_at',
        type: 'date',
      },
    ],
  },
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'blg_id',
        type: 'int',
      },
      {
        key: 'comments_count',
        type: 'int',
      },
    ],
  },
  {
    path: 'tags',
    loop: true,
    keys: [
      {
        key: 'blog_tag_languages[0].pivot.bte_name',
        type: 'string',
      },
    ],
  },
  {
    path: 'blog_category.',
    loop: false,
    keys: [
      {
        key: 'languages[0].pivot.bce_name',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      languages: [],
      comments: {
        languages: [],
      },
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT_DETAIL_IMAGE(state, data) {
      state.detail.image = data.image
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    DELETE(state, items) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          if (items.includes(value.data[i].blg_id)) {
            state.items[key].data.splice(i, 1)
            i = i - 1
          }
        }
      })
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const indexComments = state.detail.comments.languages.indexOf(state.detail.comments.languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.languages
      const oldCommentLang = state.detail.comments.languages
      state.detail = Object.assign({}, item)
      state.detail.languages = oldLang
      state.detail.comments.languages = oldCommentLang
      if (index !== -1) {
        state.detail.languages.splice(index, 1)
      }
      if (indexComments !== -1) {
        state.detail.comments.languages.splice(indexComments, 1)
      }
      state.detail.comments.languages.push({
        lge_id: store.getters['language/active'],
        items: item.comments,
      })
      state.detail.languages.push({
        lge_id: store.getters['language/active'],
        ble_content: item.languages.length > 0 ? item.languages[0].pivot.ble_content : undefined,
        ble_name: item.languages.length > 0 ? item.languages[0].pivot.ble_name : undefined,
        ble_is_active: item.languages.length > 0 ? item.languages[0].pivot.ble_is_active : true,
      })
      state.detail.tags = item.tags.map(x => x.tag_id.toString())
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/blog/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/blog/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/blog', items, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.post('/blog/' + id, item, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('SET_CURRENT_DETAIL_IMAGE', response.data.data)
          dealWithSuccess({ data: response.data.data, message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/blog', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { blg_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageDetail: (state) => {
      let comments
      if (state.detail.comments.languages !== undefined && state.detail.comments.languages.length > 0) {
        comments = state.detail.comments.languages.find(x => x.lge_id === store.getters['language/active'])
      }
      return {
        languages: state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined,
        comments: comments !== undefined ? comments.items : [],
      }
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
