export default {
  state: {
    access_token: localStorage.getItem('access_token') || sessionStorage.getItem('access_token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
  },
  mutations: {
    UPDATE_ACCESS_TOKEN(state, { accessToken }) {
      state.access_token = accessToken
    },
    SET_REFRESH_TOKEN(state, { refreshToken }) {
      state.refresh_token = refreshToken
    },
  },
  actions: {},
  getters: {
    access_token: state => state.access_token,
    refresh_token: state => state.refresh_token,
  },
}
