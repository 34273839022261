import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    refund({ commit }, data) {
      return new Promise((resolve, reject) => {
        $axios.post('/services/payments/com-gate/refund', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          dealWithSuccess({ message: 'OK!', description: 'Refundace proběhla v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
  },
}
