export default {
  namespaced: true,
  state: {
    attributes: [
      {
        key: 'created_at',
        name: 'Vytvořeno',
        type: 'date',
      }, {
        key: 'updated_at',
        name: 'Naposledny upraveno',
        type: 'date',
      }, {
        key: 'zasilkovna_id',
        name: 'ID v zásilkovně',
        type: 'number',
      }, {
        key: 'name',
        name: 'Město, ulice a městská část',
        type: 'string',
      }, {
        key: 'place',
        name: 'Název místa',
        type: 'string',
      }, {
        key: 'city',
        name: 'Město',
        type: 'string',
      }, {
        key: 'street',
        name: 'Ulice',
        type: 'string',
      }, {
        key: 'zip',
        name: 'PSČ',
        type: 'string',
      }, {
        key: 'credit_card_payment',
        name: 'Možnost platby kartou',
        type: 'bool',
      }, {
        key: 'opening_hours',
        name: 'Otevírací doba',
        type: 'hours',
      },
    ],
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    getAttributes: (state) => {
      return state.attributes
    },
    renderAddress: (state) => (service) => {
      return '<a target="_blank" class="utils__link--blue utils__link--underlined" href="' + process.env.VUE_APP_ZASILKOVNA_DETAIL_URL + service.data.zasilkovna_id + '">Více na webu Zásilkovny</a>'
    },
  },
}
