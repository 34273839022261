export function setStoreGroups(item, state, discountName, groupDiscountsName) {
  state.detail.private_groups = []
  state.detail.public_groups = []
  item[groupDiscountsName].forEach(x => {
    if (!x.is_private) {
      state.detail.public_groups.push({
        grp_id: x.grp_id,
        [discountName]: x.pivot[discountName],
        grp_name: x.grp_name,
      })
    } else {
      state.detail.private_groups.push({
        grp_id: x.grp_id,
        [discountName]: x.pivot[discountName],
        grp_name: x.grp_name,
      })
    }
  })
}
