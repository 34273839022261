import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'mur_id',
        type: 'int',
      },
      {
        key: 'mur_name',
        type: 'string',
      },
      {
        key: 'mur_url',
        type: 'string',
      },
      {
        key: 'products_count',
        type: 'int',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      languages: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.languages
      state.detail = Object.assign({}, item)
      state.detail.languages = oldLang
      if (index !== -1) {
        state.detail.languages.splice(index, 1)
      }
      state.detail.languages.push({ lge_id: store.getters['language/active'], mle_description: item.languages.length > 0 ? item.languages[0].pivot.mle_description : '' })
    },
    DELETE(state, items) {
      for (let i = 0; i < state.items.data.length; i++) {
        if (items.includes(state.items.data[i].mur_id)) {
          state.items.data.splice(i, 1)
          i = i - 1
        }
      }
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/manufacturer', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/manufacturer/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/manufacturer', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { mur_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/manufacturer', items, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.post('/manufacturer/' + id, item, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('SET_CURRENT_DETAIL_IMAGE', response.data.data)
          resolve()
          dealWithSuccess({ data: response.data.data, message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filteredItems: (state) => (searchText = '') => {
      const items = state.items.data !== undefined ? state.items.data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(items, filteredKeys, searchText)
      }
      return items
    },
    currentLanguageDetail: (state) => {
      return state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
