export const getLeftMenuData = [
  {
    title: 'Můj účet',
    key: 'profile',
    url: '/profile',
    hidden: true,
  },
  {
    title: 'Error 404',
    key: 'error-404',
    url: '/404',
    hidden: true,
  },
  {
    title: 'Error 403',
    key: 'error-403',
    url: '/403',
    hidden: true,
  },
  {
    title: 'Error 500',
    key: 'error-500',
    url: '/500',
    hidden: true,
  },
  {
    title: 'Nástěnka',
    key: 'dashboard',
    url: '/dashboard',
    icon: 'home-outline',
  },
  {
    title: 'Test',
    key: 'test',
    url: '/test',
    icon: 'flask-outline',
    hidden: true,
  },
  {
    title: 'Aktualizace',
    key: 'releaseNotesList',
    url: '/release-notes',
    hidden: true,
  },
  {
    title: 'Blog',
    key: 'blog',
    icon: 'journal-outline',
    parentUrl: '/blog',
    children: [
      {
        title: 'Přidat',
        key: 'blogAdd',
        url: '/blog/add',
      },
      {
        title: 'Upravit',
        key: 'blogDetail',
        url: '/blog/detail/*',
        hidden: true,
      },
      {
        title: 'Seznam',
        key: 'blogList',
        url: '/blog',
      },
      {
        title: 'Kategorie',
        key: 'blogCategory',
        icon: 'list-outline',
        parentUrl: '/blog/category',
        children: [
          {
            title: 'Přidat',
            key: 'blogCategoryAdd',
            url: '/blog/category/add',
          },
          {
            title: 'Seřadit',
            key: 'blogCategorySort',
            url: '/blog/category/sort',
          },
          {
            title: 'Upravit',
            key: 'blogCategoryDetail',
            url: '/blog/category/detail/*',
            hidden: true,
          },
          {
            title: 'Seznam',
            key: 'blogCategoryList',
            url: '/blog/category',
          },
        ],
      },
      {
        title: 'Tagy',
        key: 'blogTag',
        icon: 'pricetags-outline',
        parentUrl: '/blog/tag',
        children: [
          {
            title: 'Přidat',
            key: 'blogTagAdd',
            url: '/blog/tag/add',
          },
          {
            title: 'Upravit',
            key: 'blogTagDetail',
            url: '/blog/tag/detail/*',
            hidden: true,
          },
          {
            title: 'Seznam',
            key: 'blogTagList',
            url: '/blog/tag',
          },
        ],
      },
    ],
  },
  {
    title: 'Články',
    key: 'article',
    icon: 'reader-outline',
    parentUrl: '/article/type/dte',
    children: [
      {
        title: 'Přidat',
        key: 'articleAdd',
        url: '/article/type/dte/add',
      },
      {
        title: 'Upravit',
        key: 'articleDetail',
        url: '/article/type/dte/detail/*',
        hidden: true,
      },
      {
        title: 'Seznam',
        key: 'articleList',
        url: '/article/type/dte',
      },
      {
        title: 'Typy',
        key: 'articleType',
        icon: 'color-filter-outline',
        children: [
          {
            title: 'Často kladené otázky',
            key: 'articleFaq',
            parentUrl: '/article/type/faq',
            children: [
              {
                title: 'Seznam',
                key: 'articleFaqList',
                url: '/article/type/faq',
              },
              {
                title: 'Přidat',
                key: 'articleFaqAdd',
                url: '/article/type/faq/add',
              },
              {
                title: 'Upravit',
                key: 'articleFaqDetail',
                url: '/article/type/faq/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Galerie',
            key: 'articleGallery',
            parentUrl: '/article/type/gly',
            children: [
              {
                title: 'Seznam',
                key: 'articleGalleryList',
                url: '/article/type/gly',
              },
              {
                title: 'Přidat',
                key: 'articleGalleryAdd',
                url: '/article/type/gly/add',
              },
              {
                title: 'Upravit',
                key: 'articleGalleryDetail',
                url: '/article/type/gly/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Kdo jsme',
            key: 'articleWhoWeAre',
            parentUrl: '/article/type/wie',
            children: [
              {
                title: 'Seznam',
                key: 'articleWhoWeAreList',
                url: '/article/type/wie',
              },
              {
                title: 'Přidat',
                key: 'articleWhoWeAreAdd',
                url: '/article/type/wie/add',
              },
              {
                title: 'Upravit',
                key: 'articleWhoWeAreDetail',
                url: '/article/type/wie/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Kontakt',
            key: 'articleContact',
            parentUrl: '/article/type/ctt',
            children: [
              {
                title: 'Seznam',
                key: 'articleContactList',
                url: '/article/type/ctt',
              },
              {
                title: 'Přidat',
                key: 'articleContactAdd',
                url: '/article/type/ctt/add',
              },
              {
                title: 'Upravit',
                key: 'articleContactDetail',
                url: '/article/type/ctt/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Krok',
            key: 'articleStep',
            parentUrl: '/article/type/stp',
            children: [
              {
                title: 'Seznam',
                key: 'articleStepList',
                url: '/article/type/stp',
              },
              {
                title: 'Přidat',
                key: 'articleStepAdd',
                url: '/article/type/stp/add',
              },
              {
                title: 'Upravit',
                key: 'articleStepListDetail',
                url: '/article/type/stp/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Nabídka',
            key: 'articleOffer',
            parentUrl: '/article/type/doy',
            children: [
              {
                title: 'Seznam',
                key: 'articleOfferList',
                url: '/article/type/doy',
              },
              {
                title: 'Přidat',
                key: 'articleOfferAdd',
                url: '/article/type/doy/add',
              },
              {
                title: 'Upravit',
                key: 'articleOfferDetail',
                url: '/article/type/doy/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Nabízené služby',
            key: 'articleFeatures',
            parentUrl: '/article/type/cfe',
            children: [
              {
                title: 'Seznam',
                key: 'articleFeaturesList',
                url: '/article/type/cfe',
              },
              {
                title: 'Přidat',
                key: 'articleFeaturesAdd',
                url: '/article/type/cfe/add',
              },
              {
                title: 'Upravit',
                key: 'articleFeaturesDetail',
                url: '/article/type/cfe/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'O nás',
            key: 'articleAboutUs',
            parentUrl: '/article/type/aus',
            children: [
              {
                title: 'Seznam',
                key: 'articleAboutUsList',
                url: '/article/type/aus',
              },
              {
                title: 'Přidat',
                key: 'articleAboutUsAdd',
                url: '/article/type/aus/add',
              },
              {
                title: 'Upravit',
                key: 'articleAboutUsDetail',
                url: '/article/type/aus/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Reference',
            key: 'articleTestimonials',
            parentUrl: '/article/type/ttl',
            children: [
              {
                title: 'Seznam',
                key: 'articleTestimonialsList',
                url: '/article/type/ttl',
              },
              {
                title: 'Přidat',
                key: 'articleTestimonialsAdd',
                url: '/article/type/ttl/add',
              },
              {
                title: 'Upravit',
                key: 'articleTestimonialsDetail',
                url: '/article/type/ttl/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Slide',
            key: 'articleSlide',
            parentUrl: '/article/type/sle',
            children: [
              {
                title: 'Seznam',
                key: 'articleSlideList',
                url: '/article/type/sle',
              },
              {
                title: 'Přidat',
                key: 'articleSlideAdd',
                url: '/article/type/sle/add',
              },
              {
                title: 'Upravit',
                key: 'articleSlideDetail',
                url: '/article/type/sle/detail/*',
                hidden: true,
              },
            ],
          },
          {
            title: 'Tým',
            key: 'articleTeam',
            parentUrl: '/article/type/mom',
            children: [
              {
                title: 'Seznam',
                key: 'articleTeamList',
                url: '/article/type/mom',
              },
              {
                title: 'Přidat',
                key: 'articleTeamAdd',
                url: '/article/type/mom/add',
              },
              {
                title: 'Upravit',
                key: 'articleTeamDetail',
                url: '/article/type/mom/detail/*',
                hidden: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Dotazníky',
    key: 'survey',
    parentUrl: '/survey',
    icon: 'stats-chart-outline',
    children: [
      {
        title: 'Přidat',
        key: 'surveyAdd',
        url: '/survey/add',
      },
      {
        title: 'Seznam',
        key: 'surveyList',
        url: '/survey',
      },
      {
        title: 'Detail',
        key: 'surveyDetail',
        url: '/survey/detail/*',
        hidden: true,
      },
      {
        title: 'Statistiky',
        key: 'surveyTrend',
        url: '/survey/trend/*',
        hidden: true,
      },
    ],
  },
  {
    title: 'E-shop',
    key: 'ecommerce',
    icon: 'desktop-outline',
    children:
      [
        {
          title: 'Dopravy',
          key: 'ecommerceDelivery',
          icon: 'car-outline',
          parentUrl: '/ecommerce/delivery',
          children: [
            {
              title: 'Přidat',
              key: 'ecommerceDeliveryAdd',
              url: '/ecommerce/delivery/add',
            },
            {
              title: 'Seznam',
              key: 'ecommerceDeliveryList',
              url: '/ecommerce/delivery',
            },
            {
              title: 'Upravit',
              key: 'ecommerceDeliveryDetail',
              url: '/ecommerce/delivery/detail/*',
              hidden: true,
            },
          ],
        },
        {
          title: 'Dopravní služby',
          key: 'ecommerceDeliveryServices',
          icon: '/resources/images/icons/courier.svg',
          iconType: 'image',
          parentUrl: '/ecommerce/delivery-services',
          children: [
            {
              title: 'Seznam',
              key: 'ecommerceDeliveryServicesList',
              url: '/ecommerce/delivery-services',
            },
          ],
        },
        {
          title: 'Feedy',
          key: 'ecommerceFeeds',
          icon: 'cloud-download-outline',
          parentUrl: '/ecommerce/feeds',
          children: [
            {
              title: 'Seznam',
              key: 'ecommerceFeedsList',
              url: '/ecommerce/feeds',
            },
            {
              title: 'Upravit',
              key: 'ecommerceFeedsDetail',
              url: '/ecommerce/feeds/detail/*',
              hidden: true,
            },
          ],
        },
        {
          title: 'Kategorie',
          key: 'ecommerceCategory',
          icon: 'list-outline',
          parentUrl: '/ecommerce/category',
          children: [
            {
              title: 'Přidat',
              key: 'ecommerceCategoryAdd',
              url: '/ecommerce/category/add',
            },
            {
              title: 'Upravit',
              key: 'ecommerceCategoryDetail',
              url: '/ecommerce/category/detail/*',
              hidden: true,
            },
            {
              title: 'Seřadit',
              key: 'ecommerceCategorySort',
              url: '/ecommerce/category/sort',
            },
            {
              title: 'Seznam',
              key: 'ecommerceCategoryList',
              url: '/ecommerce/category',
            },
          ],
        },
        {
          title: 'Kurzy měn',
          key: 'ecommerceCurrency',
          icon: 'logo-usd',
          parentUrl: '/ecommerce/currency',
          children: [
            {
              title: 'Seznam',
              key: 'ecommerceCurrencyList',
              url: '/ecommerce/currency',
            },
          ],
        },
        {
          title: 'Objednávky',
          key: 'ecommerceOrder',
          icon: 'cart-outline',
          url: '/ecommerce/order',
          parentUrl: '/ecommerce/order',
          children: [
            {
              title: 'Detail',
              key: 'ecommerceOrderDetail',
              url: '/ecommerce/order/detail/*',
              hidden: true,
            },
          ],
        },
        {
          title: 'Parametry',
          key: 'ecommerceParameter',
          icon: 'funnel-outline',
          parentUrl: '/ecommerce/parameter',
          children: [
            {
              title: 'Přidat',
              key: 'ecommerceParameterAdd',
              url: '/ecommerce/parameter/add',
            },
            {
              title: 'Seznam',
              key: 'ecommerceParameterList',
              url: '/ecommerce/parameter',
            },
            {
              title: 'Upravit',
              key: 'ecommerceParameterDetail',
              url: '/ecommerce/parameter/detail/*',
              hidden: true,
            },
          ],
        },
        {
          title: 'Platby',
          key: 'ecommercePayment',
          icon: 'card-outline',
          parentUrl: '/ecommerce/payment',
          children: [
            {
              title: 'Upravit',
              key: 'ecommercePaymentDetail',
              url: '/ecommerce/payment/detail/*',
              hidden: true,
            },
            {
              title: 'Přidat',
              key: 'ecommercePaymentAdd',
              url: '/ecommerce/payment/add',
            },
            {
              title: 'Seznam',
              key: 'ecommercePaymentList',
              url: '/ecommerce/payment',
            },
          ],
        },
        {
          title: 'Platební služby',
          key: 'ecommercePaymentServices',
          icon: '/resources/images/logo-visa.svg',
          iconType: 'image',
          parentUrl: '/ecommerce/payment-services',
          children: [
            {
              title: 'Seznam',
              key: 'ecommercePaymentServicesList',
              url: '/ecommerce/payment-services',
            },
          ],
        },
        {
          title: 'Produkty',
          key: 'ecommerceProduct',
          icon: 'logo-apple',
          parentUrl: '/ecommerce/product/',
          children: [
            {
              title: 'Přidat',
              key: 'ecommerceProductAdd',
              url: '/ecommerce/product/add',
            },
            {
              title: 'Hromadně upravit',
              key: 'ecommerceProductMassEdit',
              url: '/ecommerce/product/mass-edit',
              hidden: true,
            },
            {
              title: 'Upravit',
              key: 'ecommerceProductDetail',
              url: '/ecommerce/product/detail/*',
              hidden: true,
            },
            {
              title: 'Seznam',
              key: 'ecommerceProductListCategory',
              url: '/ecommerce/product/*',
              hidden: true,
            },
            {
              title: 'Seznam',
              key: 'ecommerceProductList',
              url: '/ecommerce/product/',
            },
          ],
        },
        {
          title: 'Štítky',
          key: 'ecommerceSticker',
          icon: 'bookmark-outline',
          parentUrl: '/ecommerce/sticker',
          children: [
            {
              title: 'Přidat',
              key: 'ecommerceStickerAdd',
              url: '/ecommerce/sticker/add',
            },
            {
              title: 'Upravit',
              key: 'ecommerceStickerDetail',
              url: '/ecommerce/sticker/detail/*',
              hidden: true,
            },
            {
              title: 'Seřadit',
              key: 'ecommerceStickerSort',
              url: '/ecommerce/sticker/sort',
            },
            {
              title: 'Seznam',
              key: 'ecommerceStickerList',
              url: '/ecommerce/sticker',
            },
          ],
        },
        {
          title: 'Výrobci',
          key: 'ecommerceManufacturer',
          icon: 'construct-outline',
          parentUrl: '/ecommerce/manufacturer',
          children: [
            {
              title: 'Přidat',
              key: 'ecommerceManufacturerAdd',
              url: '/ecommerce/manufacturer/add',
            },
            {
              title: 'Upravit',
              key: 'ecommerceManufacturerDetail',
              url: '/ecommerce/manufacturer/detail/*',
              hidden: true,
            },
            {
              title: 'Seznam',
              key: 'ecommerceManufacturerList',
              url: '/ecommerce/manufacturer',
            },
          ],
        },
      ],
  },
  {
    title: 'Kontakty podle stránek',
    key: 'companyContacts',
    icon: 'mail-outline',
    parentUrl: '/company-contacts',
    children: [
      {
        title: 'Přidat',
        key: 'companyContactsAdd',
        url: '/company-contacts/add',
      },
      {
        title: 'Upravit',
        key: 'companyContactsDetail',
        url: '/company-contacts/detail/*',
        hidden: true,
      },
      {
        title: 'Seznam',
        key: 'companyContactsList',
        url: '/company-contacts',
      },
    ],
  },
  {
    title: 'Menu',
    key: 'menu',
    icon: 'menu-outline',
    children: [
      {
        title: 'Hlavní',
        key: 'menuMain',
        parentUrl: '/menu/main',
        children: [
          {
            title: 'Přidat',
            key: 'menuMainAdd',
            url: '/menu/main/add',
          },
          {
            title: 'Upravit',
            key: 'menuMainDetail',
            url: '/menu/main/detail/*',
            hidden: true,
          },
          {
            title: 'Seřadit',
            key: 'menuMainSort',
            url: '/menu/main/sort',
          },
          {
            title: 'Seznam',
            key: 'menuMainList',
            url: '/menu/main',
          },
        ],
      },
      {
        title: 'Zápatí',
        key: 'menuFooter',
        parentUrl: '/menu/footer',
        children: [
          {
            title: 'Přidat',
            key: 'menuFooterAdd',
            url: '/menu/footer/add',
          },
          {
            title: 'Upravit',
            key: 'menuFooterDetail',
            url: '/menu/footer/detail/*',
            hidden: true,
          },
          {
            title: 'Seřadit',
            key: 'menuFooterSort',
            url: '/menu/footer/sort',
          },
          {
            title: 'Seznam',
            key: 'menuFooterList',
            url: '/menu/footer',
          },
          {
            title: 'Kategorie',
            key: 'menuFooterTitle',
            icon: 'list-outline',
            parentUrl: '/menu/footer/category',
            children: [
              {
                title: 'Přidat',
                key: 'menuFooterTitleAdd',
                url: '/menu/footer/category/add',
              },
              {
                title: 'Seřadit',
                key: 'menuFooterTitleSort',
                url: '/menu/footer/category/sort',
              },
              {
                title: 'Upravit',
                key: 'menuFooterTitleDetail',
                url: '/menu/footer/category/detail/*',
                hidden: true,
              },
              {
                title: 'Seznam',
                key: 'menuFooterTitleList',
                url: '/menu/footer/category',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Meta Keywords',
    key: 'metaKeywords',
    icon: 'text-outline',
    parentUrl: '/meta-keywords',
    children: [
      {
        title: 'Přidat',
        key: 'metaKeywordsAdd',
        url: '/meta-keywords/add',
      },
      {
        title: 'Seznam',
        key: 'metaKeywordsList',
        url: '/meta-keywords',
      },
      {
        title: 'Upravit',
        key: 'metaKeywordsDetail',
        url: '/meta-keywords/detail/*',
        hidden: true,
      },
    ],
  },
  {
    title: 'Obrázky',
    key: 'image',
    icon: 'image-outline',
    parentUrl: '/image',
    children: [
      {
        title: 'Přidat',
        key: 'imageAdd',
        url: '/image/add',
      },
      {
        title: 'Upravit',
        key: 'imageDetail',
        url: '/image/detail/*',
        hidden: true,
      },
      {
        title: 'Seznam',
        key: 'imageList',
        url: '/image',
      },
      {
        title: 'Kategorie',
        key: 'imageCategory',
        icon: 'list-outline',
        parentUrl: '/image/category',
        children: [
          {
            title: 'Upravit',
            key: 'imageCategoryDetail',
            url: '/image/category/detail/*',
            hidden: true,
          },
          {
            title: 'Přidat',
            key: 'imageCategoryAdd',
            url: '/image/category/add',
          },
          {
            title: 'Seznam',
            key: 'imageCategoryList',
            url: '/image/category',
          },
        ],
      },
    ],
  },
  {
    title: 'Skupiny',
    key: 'group',
    icon: 'people-outline',
    parentUrl: '/group',
    children: [
      {
        title: 'Přidat',
        key: 'groupAdd',
        url: '/group/add',
      },
      {
        title: 'Seznam',
        key: 'groupList',
        url: '/group',
      },
      {
        title: 'Upravit',
        key: 'groupDetail',
        url: '/group/detail/*',
        hidden: true,
      },
    ],
  },
  {
    title: 'Soubory',
    key: 'file',
    icon: 'document-outline',
    parentUrl: '/file',
    children: [
      {
        title: 'Přidat',
        key: 'fileAdd',
        url: '/file/add',
      },
      {
        title: 'Upravit',
        key: 'fileDetail',
        url: '/file/detail/*',
        hidden: true,
      },
      {
        title: 'Seznam',
        key: 'fileList',
        url: '/file',
      },
      {
        title: 'Kategorie',
        key: 'fileCategory',
        icon: 'list-outline',
        parentUrl: '/file/category',
        children: [
          {
            title: 'Přidat',
            key: 'fileCategoryAdd',
            url: '/file/category/add',
          },
          {
            title: 'Seznam',
            key: 'fileCategoryList',
            url: '/file/category',
          },
          {
            title: 'Upravit',
            key: 'fileCategoryDetail',
            url: '/file/category/detail/*',
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Uživatelé',
    key: 'user',
    icon: 'person-outline',
    children: [
      {
        title: 'Administrátoři',
        key: 'userAdmin',
        icon: 'shield-outline',
        parentUrl: '/user/admin',
        children: [
          {
            title: 'Přidat',
            key: 'userAdminAdd',
            url: '/user/admin/add',
          },
          {
            title: 'Seznam',
            key: 'userAdminList',
            url: '/user/admin',
          },
          {
            title: 'Detail',
            key: 'userAdminDetail',
            url: '/user/admin/detail/*',
            hidden: true,
          },
          {
            title: 'Edit',
            key: 'userAdminEdit',
            url: '/user/admin/edit/*',
            hidden: true,
          },
        ],
      },
      {
        title: 'Zákazníci',
        key: 'userRegistered',
        icon: 'walk-outline',
        parentUrl: '/user/registered',
        children: [
          {
            title: 'Seznam',
            key: 'userRegisteredList',
            url: '/user/registered',
          },
          {
            title: 'Detail',
            key: 'userRegisteredDetail',
            url: '/user/registered/detail/*',
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Zprávy',
    key: 'messages',
    icon: 'warning-outline',
    children: [
      {
        title: 'Přidat',
        key: 'messagesAdd',
        url: '/messages/add',
      },
      {
        title: 'Seznam',
        key: 'messagesList',
        url: '/messages',
      },
      {
        title: 'Detail',
        key: 'messagesDetail',
        url: '/messages/detail/*',
        hidden: true,
      },
    ],
  },
]
export const getTopMenuData = getLeftMenuData
