export default {
  namespaced: true,
  state: {
    attributes: [
      {
        key: 'created_at',
        name: 'Vytvořeno',
        type: 'date',
      }, {
        key: 'updated_at',
        name: 'Naposledny upraveno',
        type: 'date',
      }, {
        key: 'post_office_id',
        name: 'ID v poště',
        type: 'number',
      }, {
        key: 'name',
        name: 'Název',
        type: 'string',
      }, {
        key: 'address',
        name: 'Adresa',
        type: 'string',
      }, {
        key: 'district',
        name: 'Okres',
        type: 'string',
      }, {
        key: 'municipality_district_name',
        name: 'Název městské části',
        type: 'string',
      }, {
        key: 'municipality_name',
        name: 'Název obce',
        type: 'string',
      }, {
        key: 'zip',
        name: 'PSČ',
        type: 'string',
      }, {
        key: 'atm',
        name: 'Bankomat',
        type: 'bool',
      }, {
        key: 'parking',
        name: 'Parkoviště',
        type: 'bool',
      }, {
        key: 'opening_hours',
        name: 'Otevírací doba',
        type: 'hours',
      },
    ],
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    getAttributes: (state) => {
      return state.attributes
    },
    renderAddress: (state) => (service) => {
      return '<a target="_blank" class="utils__link--blue utils__link--underlined" href="' + process.env.VUE_APP_CESKA_POSTA_DETAIL_URL + service.data.zip + '">Více na webu České pošty</a>'
    },
  },
}
