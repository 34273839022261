import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'ser_id',
        type: 'int',
      },
      {
        key: 'color',
        type: 'string',
      },
    ],
  },
  {
    path: 'languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'label',
        type: 'string',
      },
    ],
  },
]
const colors = [
  {
    name: 'černá',
    hex: '#000',
  }, {
    name: 'červená',
    hex: '#d30000',
  }, {
    name: 'džungle',
    hex: '#29ab87',
  }, {
    name: 'fialová',
    hex: '#b200ed',
  }, {
    name: 'modrá',
    hex: '#0080FE',
  }, {
    name: 'oranžová',
    hex: '#f9a602',
  }, {
    name: 'zelená',
    hex: '#3bb143',
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      languages: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.languages
      state.detail = Object.assign({}, item)
      state.detail.languages = oldLang
      if (index !== -1) {
        state.detail.languages.splice(index, 1)
      }
      state.detail.languages.push({
        lge_id: store.getters['language/active'],
        label: item.languages.length > 0 ? item.languages[0].pivot.label : undefined,
      })
    },
    DELETE(state, items) {
      for (let i = 0; i < state.items.length; i++) {
        for (let j = 0; j < state.items[i].data.length; j++) {
          if (items.includes(state.items[i].data[j].ser_id)) {
            state.items[i].data.splice(j, 1)
            j = j - 1
          }
        }
      }
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/sticker/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/sticker/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/sticker', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: {
            data: items.map(x => {
              return { ser_id: x }
            }),
          },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putSort({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.put('/sticker', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SET_ITEMS', [])
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Seřazení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/sticker/' + id, item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/sticker', items, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageDetail: (state) => {
      return state.detail.languages.find(x => x.lge_id === store.getters['language/active'])
    },
    getDetail: (state) => {
      return state.detail
    },
    colors: () => colors,
  },
}
