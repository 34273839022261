import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'name',
        type: 'string',
      },
      {
        key: 'id',
        type: 'int',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: {
      data: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
  },
  actions: {
    list({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/payment-services', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('SET_ITEMS', response.data)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items.data,
    currentLanguage: (state) => (searchText = '') => {
      if (searchText.length > 0) {
        return filter.methods.filterItems(state.items.data, filteredKeys, searchText)
      }
      return state.items.data
    },
  },
}
