import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: 'current_language.',
    loop: false,
    keys: [
      {
        key: 'name',
        type: 'string',
      },
    ],
  },
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'id',
        type: 'int',
      },
      {
        key: 'iso',
        type: 'string',
      },
      {
        key: 'iso3',
        type: 'string',
      },
      {
        key: 'code',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/country/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getEnabled({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/country/lang/' + store.getters['language/active'] + '/enabled', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
  },
}
