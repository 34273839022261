import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'dly_id',
        type: 'int',
      },
      {
        key: 'dly_is_active',
        type: 'bool',
      },
    ],
  },
  {
    path: 'histories[0].',
    loop: false,
    keys: [
      {
        key: 'dhy_is_sum_weight_price',
        type: 'bool',
      },
      {
        key: 'dhy_date_from',
        type: 'date',
      },
      {
        key: 'languages[0].pivot.dhe_name',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      histories: [{
        languages: [],
      }],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.histories[0].languages.indexOf(state.detail.histories[0].languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.histories[0].languages
      const newItem = {
        lge_id: store.getters['language/active'],
        dhe_name: item.histories[0].languages.length > 0 ? item.histories[0].languages[0].pivot.dhe_name : undefined,
      }
      state.detail = Object.assign({}, item)
      state.detail.histories[0].languages = oldLang
      if (index !== -1) {
        state.detail.histories[0].languages.splice(index, 1)
      }
      state.detail.histories[0].languages.push(newItem)
      state.detail.payments = item.payments.map(x => x.pmt_id)
    },
    UPDATE_STATE(state, payload) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          if (payload.items.includes(value.data[i].dly_id)) {
            state.items[key].data[i].dly_is_active = payload.activated
          }
        }
      })
    },
  },
  actions: {
    putHistory({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/address/history/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    byLanguage: (state) => (searchText = '', language) => {
      const activeItems = state.items.filter(x => x.lge_id === language)
      return language !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
    },
    currentLanguageDetail: (state) => {
      return state.detail.histories[0].languages !== undefined ? state.detail.histories[0].languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
