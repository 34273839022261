import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items.data
    },
  },
  actions: {
    getList({ commit }, query = '') {
      return new Promise((resolve, reject) => {
        $axios.get('/feeds-export-google-categories' + query, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response.data)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
  },
}
