import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: 'languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'ale_name',
        type: 'string',
      }, {
        key: 'ale_is_active',
        type: 'bool',
      }, {
        key: 'updated_at',
        type: 'date',
      }, {
        key: 'created_at',
        type: 'date',
      },
    ],
  }, {
    path: '',
    loop: false,
    keys: [
      {
        key: 'ate_id',
        type: 'int',
      },
    ],
  },
]
const types = [
  {
    key: 'faq',
    title: 'Často kladené otázky',
  }, {
    key: 'wie',
    title: 'Kdo jsme',
  }, {
    key: 'sle',
    title: 'Slide',
  }, {
    key: 'cfe',
    title: 'Nabízené služby',
  }, {
    key: 'ctt',
    title: 'Kontakt',
  }, {
    key: 'stp',
    title: 'Krok',
  }, {
    key: 'aus',
    title: 'O nás',
  }, {
    key: 'gly',
    title: 'Galerie',
  }, {
    key: 'mom',
    title: 'Náš tým',
  }, {
    key: 'ttl',
    title: 'Reference',
  }, {
    key: 'doy',
    title: 'Nabídka',
  }, {
    key: 'dte',
    title: null,
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      languages: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index === -1) {
        state.items.push(items)
      } else {
        state.items.splice(index, 1)
        state.items.push(items)
      }
    },
    DELETE(state, items) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          if (items.includes(value.data[i].ate_id)) {
            state.items[key].data.splice(i, 1)
            i = i - 1
          }
        }
      })
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const oldDetail = state.detail
      state.detail = Object.assign({}, item)
      if (oldDetail.ate_id !== undefined && oldDetail.ate_id === item.ate_id) {
        state.detail.languages = oldDetail.languages
        if (index !== -1) {
          state.detail.languages.splice(index, 1)
        }
      } else {
        state.detail.languages = []
      }
      state.detail.languages.push({
        lge_id: store.getters['language/active'],
        ale_content: item.languages.length > 0 ? item.languages[0].pivot.ale_content : undefined,
        ale_title_thin: item.languages.length > 0 ? item.languages[0].pivot.ale_title_thin : undefined,
        ale_description: item.languages.length > 0 ? item.languages[0].pivot.ale_description : undefined,
        ale_title: item.languages.length > 0 ? item.languages[0].pivot.ale_title : undefined,
        ale_name: item.languages.length > 0 ? item.languages[0].pivot.ale_name : undefined,
        ale_is_active: item.languages.length > 0 ? item.languages[0].pivot.ale_is_active : true,
        updated_at: item.languages.length > 0 ? item.languages[0].pivot.updated_at : undefined,
        created_at: item.languages.length > 0 ? item.languages[0].pivot.created_at : undefined,
      })
    },
  },
  actions: {
    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/article/all/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          resolve()
        })
      })
    },
    getList({ commit }, type) {
      return new Promise((resolve, reject) => {
        $axios.get('/article/type/' + type + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          resolve()
        })
      })
    },
    getOne({ commit }, request) {
      return new Promise((resolve, reject) => {
        $axios.get('/article/type/' + request.type + '/id/' + request.id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve) => {
        $axios.post('/article', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          resolve()
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve) => {
        $axios.put('/article/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          resolve()
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/article', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { ate_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageDetail: (state) => {
      return state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
    getTypes: () => (searchText = '') => {
      return searchText.trim().length === 0 ? types : types.find(x => x.key === searchText.trim().toLowerCase())
    },
  },
}
