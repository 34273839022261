import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'name',
        type: 'string',
      }, {
        key: 'is_active',
        type: 'bool',
      }, {
        key: 'type',
        type: 'string',
      }, {
        key: 'message',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
    },
    types: [{
      type: 'WARNING',
      name: 'Oranžová',
    }, {
      type: 'DANGER',
      name: 'Červená',
    }],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    DELETE(state, items) {
      for (let i = 0; i < state.items.length; i++) {
        if (items.includes(state.items[i].id)) {
          state.items.splice(i, 1)
          i = i - 1
        }
      }
    },
    PATCH(state, payload) {
      for (let i = 0; i < state.items.length; i++) {
        if (payload.items.includes(state.items[i].id)) {
          state.items[i].is_active = payload.is_active
        }
      }
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/messages', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/messages/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, item) {
      return new Promise((resolve, reject) => {
        $axios.post('/messages', item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/messages/' + id, item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    patch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        $axios.patch('/messages', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('PATCH', payload)
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/messages', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { items: items },
        }).then(() => {
          commit('DELETE', items)
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filteredItems: (state) => (searchText = '') => {
      if (state.items.length === 0) {
        return []
      }
      if (searchText.length > 0) {
        return filter.methods.filterItems(state.items, filteredKeys, searchText)
      }
      return state.items
    },
    getDetail: state => state.detail,
    getType: (state) => (searchText = '') => {
      if (searchText.length > 0) {
        return state.types.find(x => x.type === searchText)
      }
      return null
    },
    getTypes: state => state.types,
  },
}
