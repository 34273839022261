export default {
  namespaced: true,
  state: {
    pagination: new Map(),
    defaultPagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },
  mutations: {
    SET_PAGINATION(state, { name, item }) {
      state.pagination.set(name, Object.assign({}, item))
    },
  },
  getters: {
    getDefault: (state) => {
      return state.defaultPagination
    },
    getPagination: (state) => (name = '') => {
      if (name === '' || !state.pagination.has(name)) {
        return state.defaultPagination
      }
      return state.pagination.get(name)
    },
    getQuery: (state) => (params, idName, defaultOrder) => {
      if (params === undefined) {
        return '?order_by=' + idName + ':' + defaultOrder
      }
      let url = '?page=' + params.page
      if (params.sortField !== undefined) {
        url += '&order_by=' + params.sortField + ':' + (params.sortOrder === undefined ? 'asc' : (params.sortOrder === 'descend' ? 'desc' : 'asc'))
      }
      const attributes = Object.keys(params.filters).filter(x => params.filters[x].length !== 0)
      if (attributes.length === 0) {
        return url
      }
      url += '&search='
      attributes.forEach(key => {
        url += key + ':'
        params.filters[key].forEach(value => {
          url += value.trim() + ';'
        })
        url = url.substr(0, url.length - 1)
        url += '|'
      })
      url = url.substr(0, url.length - 1)
      return url
    },
  },
}
