import Vue from 'vue'
import Router from 'vue-router'
import LoginLayout from '@/layouts/Login'
import MainLayout from '@/layouts/Main'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: MainLayout,
      meta: { authRequired: true, hidden: true },
      children: [

        // Admin
        {
          path: '/profile',
          component: () => import('./views/admin/profile'),
        },

        // Dashboard
        {
          path: '/dashboard',
          component: () => import('./views/dashboard'),
        },

        // Currency
        {
          path: '/ecommerce/currency',
          component: () => import('./views/ecommerce/currency/list'),
        },

        // Release Notes
        {
          path: '/release-notes',
          name: 'releaseNotes',
          component: () => import('./views/release-notes/list'),
        },

        // Blog
        {
          path: '/blog',
          component: () => import('./views/blog/list'),
        },
        {
          path: '/blog/add',
          component: () => import('./views/blog/add'),
        },
        {
          path: '/blog/detail/:id',
          component: () => import('./views/blog/detail'),
          name: 'blog.detail',
        },
        {
          path: '/blog/detail/:id/comments/:commentId',
          component: () => import('./views/blog/detail'),
          name: 'blog.detail.comments.detail',
        },
        {
          path: '/blog/category/add',
          component: () => import('./views/blog/category/add'),
        },
        {
          path: '/blog/category/sort',
          component: () => import('./views/blog/category/sort'),
        },
        {
          path: '/blog/category/detail/:id',
          component: () => import('./views/blog/category/detail'),
          name: 'blog.category.detail',
        },
        {
          path: '/blog/category',
          component: () => import('./views/blog/category/list'),
        },
        {
          path: '/blog/tag/add',
          component: () => import('./views/blog/tag/add'),
        },
        {
          path: '/blog/tag/detail/:id',
          component: () => import('./views/blog/tag/detail'),
          name: 'blog.tag.detail',
        },
        {
          path: '/blog/tag',
          component: () => import('./views/blog/tag/list'),
        },
        // CompanyContacts
        {
          path: '/company-contacts',
          component: () => import('./views/company-contacts/list'),
        },
        {
          path: '/company-contacts/add',
          component: () => import('./views/company-contacts/add'),
        },
        {
          path: '/company-contacts/detail/:id',
          component: () => import('./views/company-contacts/detail'),
          name: 'companyContacts.detail',
        },

        // Ecommerce
        {
          path: '/ecommerce/delivery/add',
          component: () => import('./views/ecommerce/delivery/add'),
        },
        {
          path: '/ecommerce/delivery',
          component: () => import('./views/ecommerce/delivery/list'),
        },
        {
          path: '/ecommerce/delivery/detail/:id',
          component: () => import('./views/ecommerce/delivery/detail'),
          name: 'delivery.detail',
        },
        // Messages
        {
          path: '/messages/add',
          component: () => import('./views/messages/add'),
        },
        {
          path: '/messages',
          component: () => import('./views/messages/list'),
        },
        {
          path: '/messages/detail/:id',
          component: () => import('./views/messages/detail'),
          name: 'messages.detail',
        },
        // delivery services
        {
          path: '/ecommerce/delivery-services',
          component: () => import('./views/ecommerce/delivery-services/list'),
        },
        {
          path: '/ecommerce/feeds',
          component: () => import('./views/ecommerce/feeds/list'),
        },
        {
          path: '/ecommerce/feeds/detail/:id',
          component: () => import('./views/ecommerce/feeds/detail'),
          name: 'feeds.detail',
        },
        {
          path: '/ecommerce/category/add',
          component: () => import('./views/ecommerce/category/add'),
        },
        {
          path: '/ecommerce/category/sort',
          component: () => import('./views/ecommerce/category/sort'),
        },
        {
          path: '/ecommerce/category/detail/:id',
          component: () => import('./views/ecommerce/category/detail'),
          name: 'category.detail',
        },
        {
          path: '/ecommerce/category',
          component: () => import('./views/ecommerce/category/list'),
        },
        // sticker
        {
          path: '/ecommerce/sticker/add',
          component: () => import('./views/ecommerce/sticker/add'),
        },
        {
          path: '/ecommerce/sticker/sort',
          component: () => import('./views/ecommerce/sticker/sort'),
        },
        {
          path: '/ecommerce/sticker/detail/:id',
          component: () => import('./views/ecommerce/sticker/detail'),
          name: 'sticker.detail',
        },
        {
          path: '/ecommerce/sticker',
          component: () => import('./views/ecommerce/sticker/list'),
        },
        // order
        {
          path: '/ecommerce/order',
          component: () => import('./views/ecommerce/orders/list'),
        },
        {
          path: '/ecommerce/order/detail/:id',
          component: () => import('./views/ecommerce/orders/detail'),
          name: 'order.detail',
        },
        {
          path: '/ecommerce/parameter/add',
          component: () => import('./views/ecommerce/parameter/add'),
        },
        {
          path: '/ecommerce/parameter',
          component: () => import('./views/ecommerce/parameter/list'),
        },
        {
          path: '/ecommerce/parameter/detail/:id',
          component: () => import('./views/ecommerce/parameter/detail'),
          name: 'parameter.detail',
        },
        // payment
        {
          path: '/ecommerce/payment/add',
          component: () => import('./views/ecommerce/payment/add'),
        },
        {
          path: '/ecommerce/payment',
          component: () => import('./views/ecommerce/payment/list'),
        },
        {
          path: '/ecommerce/payment/detail/:id',
          component: () => import('./views/ecommerce/payment/detail'),
          name: 'payment.detail',
        },
        // payment services
        {
          path: '/ecommerce/payment-services',
          component: () => import('./views/ecommerce/payment-services/list'),
        },
        // products
        {
          path: '/ecommerce/product/add',
          component: () => import('./views/ecommerce/product/add'),
        },
        {
          path: '/ecommerce/product/mass-edit',
          component: () => import('./views/ecommerce/product/mass-edit'),
          name: 'product.massEdit',
        },
        {
          path: '/ecommerce/product/:id',
          component: () => import('./views/ecommerce/product/list'),
          name: 'product.list',
        },
        {
          path: '/ecommerce/product/',
          component: () => import('./views/ecommerce/product/list'),
        },
        {
          path: '/ecommerce/product/detail/:id',
          component: () => import('./views/ecommerce/product/detail'),
          name: 'product.detail',
        },
        {
          path: '/ecommerce/product/detail/:id/comments/:commentId',
          component: () => import('./views/ecommerce/product/detail'),
          name: 'product.detail.comments.detail',
        },
        {
          path: '/ecommerce/product/detail/:id/reviews/:reviewId',
          component: () => import('./views/ecommerce/product/detail'),
          name: 'product.detail.reviews.detail',
        },
        {
          path: '/ecommerce/manufacturer/add',
          component: () => import('./views/ecommerce/manufacturer/add'),
        },
        {
          path: '/ecommerce/manufacturer/detail/:id',
          component: () => import('./views/ecommerce/manufacturer/detail'),
          name: 'manufacturer.detail',
        },
        {
          path: '/ecommerce/manufacturer',
          component: () => import('./views/ecommerce/manufacturer/list'),
        },

        // Article
        {
          path: '/article/type/dte/add',
          component: () => import('./views/article/add'),
        },
        {
          path: '/article/type/:type',
          component: () => import('./views/article/list'),
        },
        {
          path: '/article/type/dte/detail/:id',
          component: () => import('./views/article/detail'),
        },
        {
          path: '/article/type/faq/add',
          component: () => import('./views/article/faq/add'),
        },
        {
          path: '/article/type/faq/detail/:id',
          component: () => import('./views/article/faq/detail'),
        },
        {
          path: '/article/type/gly/add',
          component: () => import('./views/article/gallery/add'),
        },
        {
          path: '/article/type/gly/detail/:id',
          component: () => import('./views/article/gallery/detail'),
        },
        {
          path: '/article/type/wie/add',
          component: () => import('./views/article/who-we-are/add'),
        },
        {
          path: '/article/type/wie/detail/:id',
          component: () => import('./views/article/who-we-are/detail'),
        },
        {
          path: '/article/type/stp/add',
          component: () => import('./views/article/step/add'),
        },
        {
          path: '/article/type/stp/detail/:id',
          component: () => import('./views/article/step/detail'),
        },
        {
          path: '/article/type/ctt/add',
          component: () => import('./views/article/contact/add'),
        },
        {
          path: '/article/type/ctt/detail/:id',
          component: () => import('./views/article/contact/detail'),
        },
        {
          path: '/article/type/doy/add',
          component: () => import('./views/article/offer/add'),
        },
        {
          path: '/article/type/doy/detail/:id',
          component: () => import('./views/article/offer/detail'),
        },
        {
          path: '/article/type/cfe/add',
          component: () => import('./views/article/features/add'),
        },
        {
          path: '/article/type/cfe/detail/:id',
          component: () => import('./views/article/features/detail'),
        },
        {
          path: '/article/type/aus/add',
          component: () => import('./views/article/about-us/add'),
        },
        {
          path: '/article/type/aus/detail/:id',
          component: () => import('./views/article/about-us/detail'),
        },
        {
          path: '/article/type/ttl/add',
          component: () => import('./views/article/testimonials/add'),
        },
        {
          path: '/article/type/ttl/detail/:id',
          component: () => import('./views/article/testimonials/detail'),
        },
        {
          path: '/article/type/sle/add',
          component: () => import('./views/article/slide/add'),
        },
        {
          path: '/article/type/sle/detail/:id',
          component: () => import('./views/article/slide/detail'),
        },
        {
          path: '/article/type/mom/add',
          component: () => import('./views/article/team/add'),
        },
        {
          path: '/article/type/mom/detail/:id',
          component: () => import('./views/article/team/detail'),
        },

        // Image
        {
          path: '/image/add',
          component: () => import('./views/image/add'),
        },
        {
          path: '/image',
          component: () => import('./views/image/list'),
        },
        {
          path: '/image/detail/:id',
          component: () => import('./views/image/detail'),
          name: 'image.detail',
        },
        {
          path: '/image/category/add',
          component: () => import('./views/image/category/add'),
        },
        {
          path: '/image/category',
          component: () => import('./views/image/category/list'),
        },
        {
          path: '/image/category/detail/:id',
          component: () => import('./views/image/category/detail'),
          name: 'image.category.detail',
        },

        // Group
        {
          path: '/group/add',
          component: () => import('./views/group/add'),
        },
        {
          path: '/group',
          component: () => import('./views/group/list'),
        },
        {
          path: '/group/detail/:id',
          component: () => import('./views/group/detail'),
          name: 'group.detail',
        },

        // Meta Keywords
        {
          path: '/meta-keywords/add',
          component: () => import('./views/meta-keywords/add'),
        },
        {
          path: '/meta-keywords/detail/:id',
          component: () => import('./views/meta-keywords/detail'),
          name: 'meta-keywords.detail',
        },
        {
          path: '/meta-keywords',
          component: () => import('./views/meta-keywords/list'),
        },

        // File
        {
          path: '/file/add',
          component: () => import('./views/file/add'),
        },
        {
          path: '/file',
          component: () => import('./views/file/list'),
        },
        {
          path: '/file/detail/:id',
          component: () => import('./views/file/detail'),
          name: 'file.detail',
        },
        {
          path: '/file/category/add',
          component: () => import('./views/file/category/add'),
        },
        {
          path: '/file/category/detail/:id',
          component: () => import('./views/file/category/detail'),
          name: 'file.category.detail',
        },
        {
          path: '/file/category',
          component: () => import('./views/file/category/list'),
        },

        // Menu
        {
          path: '/menu/main/add',
          component: () => import('./views/menu/main/add'),
        },
        {
          path: '/menu/main/sort',
          component: () => import('./views/menu/main/sort'),
        }, {
          path: '/menu/main/detail/:id',
          component: () => import('./views/menu/main/detail'),
          name: 'menu.main.detail',
        },
        {
          path: '/menu/main',
          component: () => import('./views/menu/main/list'),
        },
        {
          path: '/menu/footer/add',
          component: () => import('./views/menu/footer/add'),
        },
        {
          path: '/menu/footer/detail/:id',
          component: () => import('./views/menu/footer/detail'),
          name: 'menu.footer.detail',
        },
        {
          path: '/menu/footer/sort',
          component: () => import('./views/menu/footer/sort'),
        },
        {
          path: '/menu/footer',
          component: () => import('./views/menu/footer/list'),
        },
        {
          path: '/menu/footer/category/add',
          component: () => import('./views/menu/footer/category/add'),
        },
        {
          path: '/menu/footer/category/sort',
          component: () => import('./views/menu/footer/category/sort'),
        },
        {
          path: '/menu/footer/category/detail/:id',
          component: () => import('./views/menu/footer/category/detail'),
          name: 'menu.footer.category.detail',
        },
        {
          path: '/menu/footer/category',
          component: () => import('./views/menu/footer/category/list'),
        },

        // Survey
        {
          path: '/survey',
          component: () => import('./views/survey/list'),
        },
        {
          path: '/survey/trend/:id',
          component: () => import('./views/survey/trend'),
          name: 'survey.trend',
        },
        {
          path: '/survey/detail/:id',
          component: () => import('./views/survey/detail'),
          name: 'survey.detail',
        },
        {
          path: '/survey/add',
          component: () => import('./views/survey/add'),
        },

        // Users
        {
          path: '/user/registered',
          component: () => import('./views/user/registered/list'),
        },
        {
          path: '/user/registered/detail/:id',
          name: 'user.registered.detail',
          component: () => import('./views/user/registered/detail'),
        },
        {
          path: '/user/admin',
          component: () => import('./views/user/admin/list'),
        },
        {
          path: '/user/admin/detail/:id',
          name: 'user.admin.detail',
          component: () => import('./views/user/admin/detail'),
        },
        {
          path: '/user/admin/edit/:id',
          name: 'user.admin.edit',
          component: () => import('./views/user/admin/edit'),
        },
        {
          path: '/user/admin/add',
          component: () => import('./views/user/admin/add'),
        },

        // 404
        {
          path: '/404',
          component: () => import('./views/404'),
        },
        // 403
        {
          path: '/403',
          component: () => import('./views/403'),
        },
        // 500
        {
          path: '/500',
          component: () => import('./views/500'),
        },

        // test
        {
          path: '/test',
          component: () => import('./views/test'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: LoginLayout,
      redirect: '/auth/login',
      meta: { guestRequired: true },
      children: [
        {
          path: '/auth/login',
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/forgot',
          component: () => import('./views/auth/forgot'),
        },
        {
          path: '/auth/refresh-password/:code',
          component: () => import('./views/auth/refresh-password'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: '/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.token.access_token) {
      store.commit('auth/SET_ITEM', null)
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      if (store.getters['auth/getItem'] === null || (Object.keys(store.getters['auth/getItem']).length === 0 && store.getters['auth/getItem'].constructor === Object)) {
        store.dispatch('auth/getByToken')
      }
      next()
    }
  } else if (to.matched.some(record => record.meta.guestRequired)) {
    if (store.state.token.access_token) {
      next({
        path: '/dashboard',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
