import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    isVisible: false,
    item: {},
  },
  mutations: {
    SET_ITEM(state, item) {
      state.item = item
    },
    SET_VISIBILITY(state, isVisible) {
      state.isVisible = isVisible
    },
  },
  actions: {
    getOne({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/company-information', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEM', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, item) {
      return new Promise((resolve, reject) => {
        $axios.put('/company-information', { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    getItem: state => {
      return {
        isVisible: state.isVisible,
        item: state.item,
      }
    },
  },
}
