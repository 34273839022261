export default {
  namespaced: true,
  state: {
    selectedRowKeys: new Map(),
  },
  mutations: {
    SET_KEYS(state, { name, items }) {
      state.selectedRowKeys.set(name, items)
    },
  },
  actions: {},
  getters: {
    getSelectedRowKeys: (state) => (name = '') => {
      if (name === '' || !state.selectedRowKeys.has(name)) {
        return []
      }
      return state.selectedRowKeys.get(name)
    },
  },
}
