import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'
import { setStoreGroups } from '@/store/import/storeGroups'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'put_id',
        type: 'int',
      },
      {
        key: 'put_is_active',
        type: 'bool',
      },
      {
        key: 'put_code',
        type: 'string',
      },
    ],
  },
  {
    path: 'histories[0].',
    loop: false,
    keys: [
      {
        key: 'phy_price_no_vat',
        type: 'float',
      },
    ],
  }, {
    path: 'history_languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'phe_name',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    vats: [
      0,
      15,
      21,
    ],
    items: [],
    detail: {
      languages: [],
      history: {},
      history_languages: [],
      current_options: {
        languages: [],
      },
      images: [],
      categories: [],
      manufacturers: [],
      files: [],
      parameters: [],
      public_groups: [],
      private_groups: [],
      payments: [],
      meta_keywords: [],
      deliveries: [],
      stickers: [],
      similar: [],
      reviews: {
        languages: [],
      },
      comments: {
        languages: [],
      },
    },
    stats: {
      review: {
        languages: [],
      },
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const indexHistory = state.detail.history_languages.indexOf(state.detail.history_languages.find(x => x.lge_id === store.getters['language/active']))
      const indexOptions = state.detail.current_options.languages.indexOf(state.detail.current_options.languages.find(x => x.lge_id === store.getters['language/active']))
      const indexReviews = state.detail.reviews.languages.indexOf(state.detail.reviews.languages.find(x => x.lge_id === store.getters['language/active']))
      const indexComments = state.detail.comments.languages.indexOf(state.detail.comments.languages.find(x => x.lge_id === store.getters['language/active']))
      const indexReviewStats = state.stats.review.languages.indexOf(state.stats.review.languages.find(x => x.lge_id === store.getters['language/active']))

      const oldLang = state.detail.languages
      const oldHistoryLang = state.detail.history_languages
      const oldOptionLang = state.detail.current_options.languages
      const oldReviewLang = state.detail.reviews.languages
      const oldCommentLang = state.detail.comments.languages
      const oldReviewStatsLang = state.stats.review.languages

      state.detail = Object.assign({}, item.data)
      state.detail.similar = item.data.similar_first
      state.detail.languages = oldLang
      state.detail.history = Object.assign({}, item.data.current_history)
      state.detail.history_languages = oldHistoryLang
      state.detail.current_options.languages = oldOptionLang
      state.detail.reviews.languages = oldReviewLang
      state.detail.comments.languages = oldCommentLang
      state.stats.review.languages = oldReviewStatsLang
      if (index !== -1) {
        state.detail.languages.splice(index, 1)
      }
      if (indexHistory !== -1) {
        state.detail.history_languages.splice(indexHistory, 1)
      }
      if (indexOptions !== -1) {
        state.detail.current_options.languages.splice(indexOptions, 1)
      }
      if (indexReviews !== -1) {
        state.detail.reviews.languages.splice(indexReviews, 1)
      }
      if (indexComments !== -1) {
        state.detail.comments.languages.splice(indexComments, 1)
      }
      if (indexReviewStats !== -1) {
        state.stats.review.languages.splice(indexReviewStats, 1)
      }
      state.detail.languages.push({
        lge_id: store.getters['language/active'],
        ple_description_short: item.data.languages.length > 0 ? item.data.languages[0].pivot.ple_description_short : undefined,
        ple_description_long: item.data.languages.length > 0 ? item.data.languages[0].pivot.ple_description_long : undefined,
      })
      state.detail.current_options.languages.push({
        lge_id: store.getters['language/active'],
        poe_name: item.data.current_options.map(x => {
          return x.languages.length > 0 ? x.languages[0].pivot.poe_name : undefined
        }),
      })
      state.detail.reviews.languages.push({
        lge_id: store.getters['language/active'],
        items: item.data.reviews,
      })
      state.stats.review.languages.push({
        lge_id: store.getters['language/active'],
        stats: item.reviews_stats,
      })
      state.detail.comments.languages.push({
        lge_id: store.getters['language/active'],
        items: item.data.comments,
      })
      state.detail.categories = item.data.categories.map(x => {
        return {
          cey_id: x.cey_id,
          pce_is_main: x.pivot.pce_is_main,
        }
      })
      state.detail.deliveries = item.data.deliveries.map(x => {
        return {
          dly_id: x.dly_id,
          pdy_always_hide: x.pivot.pdy_always_hide,
          pdy_fixed_price: x.pivot.pdy_fixed_price,
        }
      })
      setStoreGroups(item.data, state, 'pgt_discount', 'group_discounts')
      state.detail.images = item.data.images.map(x => {
        return {
          iae_id: x.iae_id,
          iae_format: x.iae_format,
          iae_path: x.iae_path,
          iae_name: x.iae_name,
          pie_is_main: x.pivot.pie_is_main,
          image_sizes: x.image_sizes,
        }
      })
      state.detail.history_languages.push({
        lge_id: store.getters['language/active'],
        phe_name: item.data.history_languages.length > 0 ? item.data.history_languages[0].pivot.phe_name : undefined,
        phe_url: item.data.history_languages.length > 0 ? item.data.history_languages[0].pivot.phe_url : undefined,
        phe_option_title: item.data.history_languages.length > 0 ? item.data.history_languages[0].pivot.phe_option_title : undefined,
      })
      state.detail.meta_keywords = item.data.meta_keywords.map(x => x.mkd_id.toString())
      state.detail.mur_id = item.data.mur_id === null ? null : item.data.mur_id.toString()
      state.detail.parameters = item.data.parameters.map(x => x.prr_id.toString() + '_' + x.vue_id.toString())
    },
    UPDATE_STATE(state, payload) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          if (payload.items.includes(value.data[i].put_id)) {
            state.items[key].data[i].put_is_active = payload.activated
          }
        }
      })
    },
    DELETE_REVIEW(state, items) {
      items.rvw_message = ''
      items.deleted = true
    },
    DELETE_COMMENT(state, items) {
      items.cmt_message = ''
      items.deleted = true
      if (items.parent && items.parent.children_count) {
        items.parent.children_count -= 1
      }
    },
    SET_LOADED_CHILDREN_REPLIES(state, item) {
      item.item.children = store.getters['comment/getParent']
      item.item.show = true
    },
    UPDATE_COMMENT_MESSAGE(state, item) {
      item.item.cmt_message = item.message
    },
  },
  actions: {
    getList({ commit }, ceyId = null) {
      return new Promise((resolve, reject) => {
        $axios.get('/product?cey_id=' + (ceyId !== null ? ceyId : store.getters['category/getActive']) + '&lge_id=' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/product/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    activate({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.patch('/product/' + id, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('UPDATE_STATE', { items: id, activated: true })
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Zveřejnění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    deactivate({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/product', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { put_id: x } }) },
        }).then(() => {
          commit('UPDATE_STATE', { items: items, activated: false })
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Nezveřejnění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/product/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    massUpdate({ commit }, { productIds, data }) {
      return new Promise((resolve, reject) => {
        $axios.put('/product', { products: productIds, data: data }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/product', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageIds: (state) => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      return store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data.map(x => x.put_id) : []
    },
    currentLanguageDetail: (state) => {
      let comments
      let reviews
      if (state.detail.comments.languages !== undefined && state.detail.comments.languages.length > 0) {
        comments = state.detail.comments.languages.find(x => x.lge_id === store.getters['language/active'])
      }
      if (state.detail.reviews.languages !== undefined && state.detail.reviews.languages.length > 0) {
        reviews = state.detail.reviews.languages.find(x => x.lge_id === store.getters['language/active'])
      }
      return {
        languages: state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined,
        current_options: state.detail.current_options.languages !== undefined ? state.detail.current_options.languages.find(x => x.lge_id === store.getters['language/active']) : undefined,
        history_languages: state.detail.history_languages !== undefined ? state.detail.history_languages.find(x => x.lge_id === store.getters['language/active']) : undefined,
        comments: comments !== undefined ? comments.items : [],
        reviews: reviews !== undefined ? reviews.items : [],
      }
    },
    getDetail: (state) => {
      return state.detail
    },
    getVats: (state) => {
      return state.vats
    },
    getCurrentLanguageStats: (state) => {
      let reviews
      if (state.stats.review.languages !== undefined && state.stats.review.languages.length > 0) {
        reviews = state.stats.review.languages.find(x => x.lge_id === store.getters['language/active'])
      }
      return {
        reviews: reviews !== undefined ? reviews.stats : null,
      }
    },
  },
}
