<template>
  <a-dropdown v-model="visible" :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="count">
        <a-avatar shape="square" icon="notification" size="large"
                  :class="[$style.avatar, count !== 0 ? $style.active : '']"/>
      </a-badge>
    </div>
    <div slot="overlay" class="ant-dropdown-menu" :class="$style.activity">
      <div v-if="items.data !== undefined && items.data.length > 0">
        <a-menu :selectable="false" :class="$style['menu-content']">
          <a-menu-item class="ant-dropdown-menu-item" v-for="item in items.data" :key="item.ntn_id"
                       :class="[$style.item, item.is_seen_count === 1 ? $style.selected : '']">
            <img :class="$style.icon" v-if="item.settings.iconType && item.settings.iconType === 'myIcon'"
                 :src="'/resources/images/icons/' + item.settings.icon" alt="">
            <template v-else>
              <ion-icon :name="type" :class="[$style.icon, index > 0 ? $style.edit : '']" :key="'icon'+index"
                        v-for="(type, index) in item.settings.icon"></ion-icon>
            </template>
            <div :class="$style.inner">
              <div :class="$style.title">
                <div :class="$style['right-side']">
                  <span>{{ $options.filters.fromNowDate(item.created_at) }}</span>
                  <div :class="$style.actions">
                    <a-tooltip title="Označit jako přečtené" v-if="item.is_seen_count === 1">
                      <a href="javascript:;" @click="setRead([item.ntn_id])">
                        <ion-icon name="radio-button-on-outline"></ion-icon>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Označit jako nepřečtené" v-else>
                      <a href="javascript:;" @click="setUnRead(item.ntn_id)">
                        <ion-icon name="radio-button-off-outline"></ion-icon>
                      </a>
                    </a-tooltip>
                  </div>
                </div>
                <div :class="$style.descr">
                  <MyDesc :item="item"></MyDesc>
                </div>
              </div>
            </div>
          </a-menu-item>
          <a-menu-item :class="[$style.item, $style.next]" v-if="itemsNextCount > 0">
            <a-button :loading="loading" type="primary" class="width-200 d-block m-auto"
                      size="small"
                      @click="loadNext()">Načíst dalších {{ itemsNextCount }} (zbývá {{
                items.count - items.data.length
              }})
            </a-button>
          </a-menu-item>
        </a-menu>
        <div class="position-relative text-right">
          <ul :class="$style.options">
            <li :class="$style.option">
              <a href="javascript:;" @click="setRead([])">Označit vše jako přečtené</a>
            </li>
          </ul>
        </div>
      </div>
      <a-empty class="pt-4 pb-4" v-else>
        <a-spin v-if="loading"/>
      </a-empty>
    </div>
  </a-dropdown>
</template>

<script>
import notificationMixin from '@/services/notificationMixin'
import MyDesc from '@/components/LayoutComponents/Topbar/NotificationMenu/MyDesc'

export default {
  components: { MyDesc },
  mixins: [notificationMixin],
  data() {
    return {
      visible: false,
      loading: false,
      loaded: false,
      index: 0,
      page: 0,
    }
  },
  computed: {
    itemsNextCount: function () {
      const count = this.items.count - this.items.data.length
      return count > this.items.per_page ? this.items.per_page : count
    },
    items: function () {
      return this.$store.getters['notification/getItems']
    },
    count: function () {
      return this.$store.getters['notification/getCount']
    },
  },
  methods: {
    loadNext() {
      this.loading = true
      this.page++
      this.$store.dispatch('notification/getList', this.page)
        .finally(() => {
          this.loading = false
          this.loaded = true
        })
    },
    setUnRead(id) {
      this.loading = true
      this.$store.dispatch('notification/patch', id)
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.loadCount()
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue && !this.loaded) {
        this.loadNext()
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
