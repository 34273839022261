import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    stats: [{
      title: 'Noví uživatelé',
      amount: '0',
      isCurrency: false,
      chartData: {
        series: [
          [
            2,
            4,
            6,
            8,
            20,
            26,
          ],
        ],
      },
    }, {
      title: 'Nové objednávky',
      amount: '0',
      isCurrency: false,
      chartData: {
        series: [
          [
            2,
            4,
            6,
            8,
            20,
            26,
          ],
        ],
      },
    }, {
      title: 'Objednávky celkem s DPH',
      amount: '0',
      isCurrency: true,
      chartData: {
        series: [
          [
            2,
            4,
            6,
            8,
            20,
            26,
          ],
        ],
      },
    }],
  },
  mutations: {
    SET_STATS(state, stats) {
      state.stats[0].amount = stats.data.users_count.toString()
      state.stats[1].amount = stats.data.orders_count.toString()
      state.stats[2].amount = stats.data.orders_total_sum.toString()
    },
  },
  actions: {
    get({ commit }, dates) {
      return new Promise((resolve, reject) => {
        $axios.get('/dashboard?date_from=' + dates.date_from + '&date_to=' + dates.date_to, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_STATS', response.data)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    stats: state => state.stats,
  },
}
