<template>
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <img :class="[styles.icon]" v-if="menuInfo.iconType && menuInfo.iconType === 'image'" :src="menuInfo.icon" alt="">
      <ion-icon :name="menuInfo.icon" :class="[styles.icon]" v-else-if="menuInfo.icon" />
    </span>
    <template v-for="(item, index) in menuInfo.children">
      <item
        v-if="(!item.children || item.children && item.children.filter(x => x.hidden !== true).length === 0) && !item.divider && !item.hidden"
        :menu-info="item"
        :styles="styles"
        :key="item.key"
      />
      <a-menu-divider v-if="item.divider" :key="index"/>
      <sub-menu v-if="item.children && item.children.filter(x => x.hidden !== true).length > 0" :menu-info="item" :styles="styles" :key="item.key"/>
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import Item from './item'

export default {
  name: 'SubMenu',
  components: { Item },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: Object,
    styles: Object,
  },
}
</script>
