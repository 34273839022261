<template>
  <a-layout>
    <a-layout-content>
      <div
        :class="[$style.layout, $style.light]"
        :style="{backgroundImage: `url(\'resources/images/photos/${backgroundNumber}.jpeg\')`}"
      >
        <div :class="$style.content">
          <router-view/>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
export default {
  name: 'LoginLayout',
  data: function () {
    return {
      backgroundEnabled: true,
      backgroundNumber: 7,
    }
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
  /deep/ .form-checkbox {
    margin-bottom: 0;

    .ant-form-item-control {
      text-align: right;
      line-height: 1;

      .ant-checkbox-wrapper {
        display: block;
        float: left;
      }
    }
  }

  /deep/ .logo {
    max-width: 180px;
    max-height: 180px;
    margin: 0 auto 2rem auto;
    display: block;
  }
</style>
