import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

const perPage = 10

export default {
  namespaced: true,
  state: {
    page: 0,
    items: {
      data: [],
      unread_count: 0,
    },
  },
  mutations: {
    nextPage(state) {
      state.page++
    },
    SET_ITEMS(state, items) {
      if (Array.isArray(state.items.data)) {
        items.data = state.items.data.concat(items.data)
      }
      state.items = items
    },
    ADD_COUNT(state, amount) {
      state.items.unread_count === undefined ? state.items.unread_count = amount : state.items.unread_count += amount
    },
    SET_READ(state, items) {
      if (items.length === 0) {
        state.items.unread_count = 0
        state.items.data.forEach((value) => {
          value.is_seen_count = 0
        })
      } else {
        let match = 0
        state.items.data.some((value) => {
          if (items.includes(value.id)) {
            value.is_seen_count = 0
            match += 1
          }
          if (match === items.length) {
            return true
          }
        })
        state.items.unread_count -= match
      }
    },
    SET_UNREAD(state, id) {
      state.items.unread_count += 1
      state.items.data.some((value) => {
        if (id === value.id) {
          value.is_seen_count = 1
          return true
        }
      })
    },
  },
  actions: {
    getList({ commit }, page) {
      return new Promise((resolve, reject) => {
        $axios.get('/release-notes?page=' + page, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getCount({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/release-notes/count', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    patch({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.patch('/release-notes/' + id, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SET_UNREAD', id)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/release-notes', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { id: x } }) },
        }).then(() => {
          commit('SET_READ', items)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    getPage: (state) => {
      return state.page
    },
    getItems: (state) => {
      return { count: state.items.total, data: state.items.data === undefined ? [] : state.items.data, per_page: perPage }
    },
    getCount: state => state.items.unread_count === undefined ? 0 : state.items.unread_count,
  },
}
