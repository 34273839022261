import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'uer_id',
        type: 'int',
      },
      {
        key: 'email',
        type: 'string',
      },
      {
        key: 'uer_first_name',
        type: 'string',
      },
      {
        key: 'uer_last_name',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {},
    types: [{
      tag: 'ANS',
      color: '',
      name: 'anonym',
    }, {
      tag: 'RID',
      color: 'green',
      name: 'zákazník',
    }, {
      tag: 'AMR',
      color: 'red',
      name: 'admin',
    }],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
    DELETE(state, items) {
      if (state.items.data === undefined) {
        return false
      }
      for (let i = 0; i < state.items.data.length; i++) {
        if (items.includes(state.items.data[i].uer_id)) {
          state.items.data.splice(i, 1)
          i = i - 1
        }
      }
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/user/admin', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/user/admin/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/user/admin', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/user/admin/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/user/admin', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { uer_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filteredItems: (state) => (searchText = '') => {
      const currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    getDetail: (state) => {
      return state.detail
    },
    getType: (state) => (type = '') => {
      return type === '' ? state.types : state.types.find(x => x.tag === type)
    },
  },
}
