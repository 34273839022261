import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'meu_id',
        type: 'int',
      },
      {
        key: 'meu_is_ecommerce',
        type: 'bool',
      },
      {
        key: 'meu_is_blog',
        type: 'bool',
      },
      {
        key: 'meu_is_article',
        type: 'bool',
      },
      {
        key: 'meu_in_footer',
        type: 'bool',
      },
      {
        key: 'footer_menu_title',
        type: 'string',
      },
    ],
  },
  {
    path: 'menu_languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'mle_menu_title',
        type: 'string',
      },
      {
        key: 'mle_is_active',
        type: 'bool',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    only: [],
    detail: {
      menu_languages: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    SET_CURRENT_ONLY(state, items) {
      const index = state.only.indexOf(state.only.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.only.splice(index, 1)
      }
      state.only.push(items)
    },
    DELETE(state, items) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          for (let k = 0; k < value.data[i].menus.length; k++) {
            if (items.includes(value.data[i].menus[k].meu_id)) {
              state.items[key].data[i].menus.splice(k, 1)
              k = k - 1
            }
          }
        }
      })
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.menu_languages.indexOf(state.detail.menu_languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.menu_languages
      state.detail = Object.assign({}, item)
      state.detail.menu_languages = oldLang
      if (index !== -1) {
        state.detail.menu_languages.splice(index, 1)
      }
      state.detail.menu_languages.push({
        lge_id: store.getters['language/active'],
        mle_menu_title: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.mle_menu_title : undefined,
        mle_article_title: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.mle_article_title : undefined,
        mle_url: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.mle_url : undefined,
        self_url: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.self_url : undefined,
        mle_head_title: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.mle_head_title : undefined,
        mle_is_active: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.mle_is_active : true,
        mle_meta_description: item.menu_languages.length > 0 ? item.menu_languages[0].pivot.mle_meta_description : undefined,
      })
      state.detail.articles = item.menu_articles.map(x => x.ate_id)
      state.detail.meta_keywords = item.meta_keywords.map(x => x.mkd_id.toString())
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/menu/footer/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/menu/footer/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOnly({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/menu/footer/only/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_ONLY', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/menu/footer', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { meu_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putSort({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.put('/menu/footer', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SET_ITEMS', [])
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Seřazení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/menu/footer/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/menu/footer', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      let currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      const newData = []

      currentItems.forEach(valueTitle => {
        valueTitle.menus.forEach(valueMenu => {
          if (valueTitle.languages !== undefined && valueTitle.languages[0] !== undefined) {
            valueMenu.footer_menu_title = valueTitle.languages[0].pivot.mfe_title
          } else {
            valueMenu.footer_menu_title = 'není nastaveno'
          }
          newData.push(valueMenu)
        })
      })
      currentItems = newData

      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageSort: (state) => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      return store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
    },
    itemLength: (state) => (searchText = '') => {
      const currentItems = store.getters['menuFooter/currentLanguage'](searchText)
      let count = currentItems.length
      currentItems.forEach(value => {
        if (value.children !== undefined) {
          count += value.children.length
        }
      })
      return count
    },
    currentLanguageOnly: (state) => {
      const activeItems = state.only.filter(x => x.lge_id === store.getters['language/active'])
      return store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
    },
    currentLanguageDetail: (state) => {
      return state.detail.menu_languages !== undefined ? state.detail.menu_languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
