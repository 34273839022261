<template>
  <a-modal
    v-model="updateExists"
    :title="'Nová aktualizace - ' + $appVersion"
    ok-text="Aktualizovat"
    cancel-text="Zrušit"
    @ok="refreshApp"
  >
    <p>Je dostupná nová aktualizace.</p>
  </a-modal>
</template>

<script>
import swUpdate from '@/services/swUpdate'
export default {
  name: 'SWUpdate',
  mixins: [swUpdate],
}
</script>

<style scoped>

</style>
