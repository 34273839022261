import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
    active: null,
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_ACTIVE(state, active) {
      state.active = active
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/language', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          if (store.getters['language/active'] === null) {
            commit('SET_ACTIVE', response.data[0].lge_id)
          }
          resolve(response)
        }).catch((error) => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    items: state => state.items,
    active: state => state.active,
    getById: state => (id) => {
      return state.items.find(x => x.lge_id === id)
    },
  },
}
