import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    item: null,
  },
  mutations: {
    SET_ITEM(state, item) {
      state.item = item
    },
    UPDATE_ITEM(state, item) {
      Object.assign(state.item, item)
    },
  },
  actions: {
    getByToken({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/auth/token', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEM', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error, false)
        })
      })
    },
    codeExists({ commit }, code) {
      return new Promise((resolve, reject) => {
        $axios.get('/auth/update-pass?refreshcode=' + code, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          resolve()
        })
      })
    },
    put({ commit }, item) {
      return new Promise((resolve, reject) => {
        $axios.put('/auth', { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
          commit('UPDATE_ITEM', item)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          resolve()
        })
      })
    },
    forgotPass({ commit }, item) {
      return new Promise((resolve, reject) => {
        $axios.patch('/auth/forgot-pass', { data: item }, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Byl Vám zaslán e-mail s odkazem pro obnovení hesla.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error, false)
          resolve()
        })
      })
    },
    updatePass({ commit }, item) {
      return new Promise((resolve, reject) => {
        $axios.patch('/auth/update-passs', { data: item }, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(() => {
          dealWithSuccess({ message: 'OK!', description: 'Změna hesla proběhla v pořádku.' })
          resolve()
        }).catch(error => {
          dealWithErrors(error, false)
          reject(error)
        })
      })
    },
  },
  getters: {
    getItem: (state) => state.item,
  },
}
