import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'name',
        type: 'string',
      },
      {
        key: 'id',
        type: 'int',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    days: ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'],
    items: {
      data: [],
    },
    deliveryServices: [
      { name: 'ZASILKOVNA', store: 'zasilkovnaDelivery' },
      { name: 'BALIKOVNA', store: 'balikovnaDelivery' },
      { name: 'POST_OFFICE', store: 'postOfficeDelivery' },
    ],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
  },
  actions: {
    list({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/delivery-services', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('SET_ITEMS', response.data)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items.data,
    currentLanguage: (state) => (searchText = '') => {
      if (searchText.length > 0) {
        return filter.methods.filterItems(state.items.data, filteredKeys, searchText)
      }
      return state.items.data
    },
    serviceByName: (state) => (name) => {
      if (typeof name === 'string' && name.trim().length !== 0) {
        return state.deliveryServices.find(x => x.name === name)
      }
      return false
    },
    getDays: (state) => {
      return state.days
    },
  },
}
