import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'fie_id',
        type: 'int',
      }, {
        key: 'fie_name',
        type: 'string',
      }, {
        key: 'fie_format',
        type: 'string',
      }, {
        key: 'products_count',
        type: 'int',
      },
    ],
  },
  {
    path: 'file_category.',
    loop: false,
    keys: [
      {
        key: 'fcy_name',
        type: 'string',
      },
    ],
  },
]

const perPage = 6

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {},
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    DELETE(state, items) {
      for (let i = 0; i < state.items.data.length; i++) {
        if (items.includes(state.items.data[i].fie_id)) {
          state.items.data.splice(i, 1)
          i = i - 1
        }
      }
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/file', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/file/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/file', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { fie_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/file', items, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/file/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filteredItems: (state) => (param = { searchText: '', page: 1 }) => {
      let currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      if (param.searchText.length > 0) {
        currentItems = filter.methods.filterItems(currentItems, filteredKeys, param.searchText)
      }
      return { count: currentItems.length, data: currentItems.slice(0, param.page * perPage) }
    },
    getFileById: (state) => (id) => {
      const currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      return currentItems.find(x => x.fie_id === id)
    },
    getListFilteredItems: (state) => (searchText = '') => {
      const items = state.items.data !== undefined ? state.items.data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(items, filteredKeys, searchText)
      }
      return items
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
