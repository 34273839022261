import axios from 'axios'
import axiosRetry from 'axios-retry'
import NProgress from 'vue-nprogress'
import router from '../../router'
import { notification } from 'ant-design-vue'

const nprogress = new NProgress({ parent: 'body' })
export const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_SERVER,
})
axiosRetry($axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

$axios.interceptors.request.use(config => {
  nprogress.start()
  return config
}, (error) => {
  nprogress.done()
  return Promise.reject(error)
})

$axios.interceptors.response.use(response => {
  nprogress.done()
  return response
}, (error) => {
  nprogress.done()
  return Promise.reject(error)
})

export function dealWithErrors(error, shouldRedirect = true) {
  let errorBag
  if (error.response === undefined) {
    errorBag = {
      message: 'ERROR 520: Něco se pokazilo',
      description: error,
    }
  } else if (error.response.data === undefined) {
    errorBag = {
      message: 'ERROR ' + error.response.status + ': Něco se pokazilo',
      description: error.response,
    }
  } else {
    if (shouldRedirect) {
      if (error.response.status === 403) {
        router.replace('/403')
      }
      if (error.response.status === 404) {
        router.replace('/404')
      }
      if (error.response.status === 500) {
        router.replace('/500')
      }
    }
    let description = ''
    for (const errorKey in error.response.data.errors) {
      if (Object.keys(error.response.data.errors[errorKey]).length !== 0) {
        for (const errorKey2 in error.response.data.errors[errorKey]) {
          description += error.response.data.errors[errorKey][errorKey2]
        }
      } else {
        description += error.response.data.errors[errorKey]
      }
    }
    errorBag = {
      message: 'ERROR ' + error.response.status + ': ' + error.response.data.message,
      description: description,
    }
  }
  notification.error(errorBag)
  error.errorBag = errorBag
  return error
}

export function dealWithSuccess(response) {
  notification.success({
    message: response.message,
    description: response.description,
  })
}

export function dealWithWarning(response) {
  notification.warning({
    message: response.message,
    description: response.description,
  })
}
