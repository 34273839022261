<template>
  <div class="d-inline-block mr-4">
    <a-tree-select
      showSearch
      :dropdownStyle="{ width: '300px' }"
      placeholder="Hledej v administraci"
      v-model="value"
      allowClear
      :treeDefaultExpandAll="false"
      :class="$style.extInput"
      dropdownClassName="certain-category-search-dropdown"
      :dropdownMatchSelectWidth="false"
      treeNodeFilterProp="value"
      :treeData="menuData"
      @select="goTo"
    >
      <a-icon slot="suffixIcon" type="search" class="certain-category-icon"/>
    </a-tree-select>
  </div>
</template>

<script>
import { getSearchAdminMenu } from '@/services/searchAdminMenu'

export default {
  data() {
    return {
      menuData: getSearchAdminMenu,
      value: undefined,
    }
  },
  methods: {
    goTo: function (value, node) {
      if (!node || !node.dataRef || ((node.dataRef.url && node.dataRef.url === this.$router.currentRoute.fullPath) ||
        (node.dataRef.parentUrl && node.dataRef.parentUrl === this.$router.currentRoute.fullPath))) {
        return false
      }
      if (node.dataRef.url) {
        this.$router.push(node.dataRef.url)
        return true
      }
      if (node.dataRef.parentUrl) {
        this.$router.push(node.dataRef.parentUrl)
        return true
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
