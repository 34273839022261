import Vue from 'vue'
import Vuex from 'vuex'
import token from './token'
import settings from './settings'
import language from './modules/language'
import orderBalikobot from './modules/orderBalikobot'
import deliveryDictionary from './modules/deliveryDictionary'
import feeds from './modules/feeds'
import attributes from './modules/attributes'
import article from './modules/article'
import currency from './modules/currency'
import blog from './modules/blog'
import blogCategory from './modules/blogCategory'
import survey from './modules/survey'
import comgate from './modules/comgate'
import deliveryServices from './modules/deliveryServices'
import blogTag from './modules/blogTag'
import balikovnaDelivery from './modules/balikovnaDelivery'
import googleCategory from './modules/googleCategory'
import zasilkovnaDelivery from './modules/zasilkovnaDelivery'
import balikobotServices from './modules/balikobotServices'
import postOfficeDelivery from './modules/postOfficeDelivery'
import comment from './modules/comment'
import menuFooter from './modules/menuFooter'
import menuFooterTitle from './modules/menuFooterTitle'
import companyInformation from './modules/companyInformation'
import companyContacts from './modules/companyContacts'
import country from './modules/country'
import menuMain from './modules/menuMain'
import review from './modules/review'
import metaKeyword from './modules/metaKeyword'
import releaseNotes from './modules/releaseNotes'
import notification from './modules/notification'
import image from './modules/image'
import imageCategory from './modules/imageCategory'
import sticker from './modules/sticker'
import auth from './modules/auth'
import manufacturer from './modules/manufacturer'
import file from './modules/file'
import payment from './modules/payment'
import delivery from './modules/delivery'
import parameter from './modules/parameter'
import paymentServices from './modules/paymentServices'
import address from './modules/address'
import fileCategory from './modules/fileCategory'
import value from './modules/value'
import balikobot from './modules/balikobot'
import messages from './modules/messages'
import dashboard from './modules/dashboard'
import group from './modules/group'
import category from './modules/category'
import userAdmin from './modules/userAdmin'
import product from './modules/product'
import order from './modules/order'
import myTable from './modules/myTable'
import userNote from './modules/userNote'
import userRegistered from './modules/userRegistered'
import pagination from './modules/pagination'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    pagination,
    token,
    currency,
    comgate,
    settings,
    auth,
    language,
    userNote,
    deliveryDictionary,
    balikovnaDelivery,
    googleCategory,
    zasilkovnaDelivery,
    balikobot,
    orderBalikobot,
    postOfficeDelivery,
    messages,
    myTable,
    dashboard,
    survey,
    balikobotServices,
    deliveryServices,
    feeds,
    attributes,
    address,
    parameter,
    releaseNotes,
    value,
    userAdmin,
    review,
    paymentServices,
    country,
    userRegistered,
    image,
    imageCategory,
    sticker,
    fileCategory,
    file,
    product,
    order,
    group,
    category,
    payment,
    delivery,
    notification,
    article,
    blog,
    manufacturer,
    companyInformation,
    companyContacts,
    blogCategory,
    blogTag,
    comment,
    menuFooterTitle,
    menuFooter,
    menuMain,
    metaKeyword,
  },
})
