<template>
  <a-dropdown :style="$route.name === 'releaseNotes' ? 'cursor: default;' : ''" v-model="visible" :trigger="[$route.name === 'releaseNotes' ? '': 'click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="count">
        <a-avatar shape="square" size="large" :class="[$style.avatar, count !== 0 ? $style.active : '']">
          <img style="padding: 4px;" slot="icon" :src="updatedIcon" alt="">
        </a-avatar>
      </a-badge>
    </div>
    <div slot="overlay" class="ant-dropdown-menu" :class="$style.activity">
      <div v-if="items.data !== undefined && items.data.length > 0">
        <div style="text-align: right;padding: 10px">
          <router-link to="/release-notes">Zobrazit vše</router-link>
        </div>
        <a-menu :selectable="false" :class="$style['menu-content']">
          <a-menu-item class="ant-dropdown-menu-item" v-for="item in items.data" :key="item.id" :class="[$style.item, item.is_seen_count === 1 ? $style.selected : '']">
            <img :class="[$style.icon, index > 0 ? $style.edit : '']" :src="getUpdateTypeIcon(item)" alt="">
            <div :class="$style.inner">
              <div :class="$style.title">
                {{ item.title }}
                <div :class="$style['right-side']">
                  <span>{{ $options.filters.fromNowDate(item.created_at) }}</span>
                  <div :class="$style.actions">
                    <a-tooltip title="Označit jako přečtené" v-if="item.is_seen_count === 1">
                      <a href="javascript:;" @click="setRead([item.id])">
                        <ion-icon name="radio-button-on-outline"></ion-icon>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Označit jako nepřečtené" v-else>
                      <a href="javascript:;" @click="setUnRead(item.id)">
                        <ion-icon name="radio-button-off-outline"></ion-icon>
                      </a>
                    </a-tooltip>
                  </div>
                </div>
                <div :class="$style.descr">
                  <router-link :to="'/release-notes#release-note-' + item.id"><b>#{{ item.identifier }}</b></router-link>
                </div>
              </div>
            </div>
          </a-menu-item>
          <a-menu-item :class="[$style.item, $style.next]" v-if="itemsNextCount > 0">
            <a-button :loading="loading" type="primary" class="width-200 d-block m-auto"
                      size="small"
                      @click="loadNext()">Načíst dalších {{ itemsNextCount }} (zbývá {{ items.count - items.data.length }})
            </a-button>
          </a-menu-item>
        </a-menu>
        <div class="position-relative text-right">
          <ul :class="$style.options">
            <li :class="$style.option">
              <a href="javascript:;" @click="setRead([])">Označit vše jako přečtené</a>
            </li>
          </ul>
        </div>
      </div>
      <a-empty class="pt-4 pb-4" v-else>
        <a-spin v-if="loading" />
      </a-empty>
    </div>
  </a-dropdown>
</template>

<script>
export default {
  data() {
    return {
      updatedIcon: '/resources/images/updated.svg',
      adminIcon: '/resources/images/admin.svg',
      ecommerceIcon: '/resources/images/online-shopping.svg',
      visible: false,
      loading: false,
      loaded: false,
      index: 0,
    }
  },
  computed: {
    itemsNextCount: function () {
      const count = this.items.count - this.items.data.length
      return count > this.items.per_page ? this.items.per_page : count
    },
    page: function() {
      return this.$store.getters['releaseNotes/getPage']
    },
    items: function() {
      return this.$store.getters['releaseNotes/getItems']
    },
    count: function() {
      return this.$store.getters['releaseNotes/getCount']
    },
  },
  methods: {
    getUpdateTypeIcon(item) {
      if (item.title === 'Administrace') {
        return this.adminIcon
      }
      return this.ecommerceIcon
    },
    loadNext() {
      this.loading = true
      this.$store.commit('releaseNotes/nextPage')
      this.$store.dispatch('releaseNotes/getList', this.page)
        .finally(() => {
          this.loading = false
          this.loaded = true
        })
    },
    setRead(ids, item = null) {
      if (item !== null && item.is_seen_count === 0) {
        return false
      }
      this.loading = true
      this.$store.dispatch('releaseNotes/delete', ids)
        .then(() => {
          this.loadCount()
        })
        .finally(() => {
          this.loading = false
        })
    },
    setUnRead(id) {
      this.loading = true
      this.$store.dispatch('releaseNotes/patch', id)
        .finally(() => {
          this.loading = false
        })
    },
    loadCount() {
      this.$store.dispatch('releaseNotes/getCount')
    },
  },
  created() {
    this.loadCount()
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue && !this.loaded && this.items.data.length === 0) {
        this.loadNext()
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
