<template>
  <div :class="$style.topbar">
    <div class="mr-auto">
      <cui-live-search/>
    </div>
    <updates class="mr-3"/>
    <cui-notification-menu class="mr-3"/>
    <cui-profile-menu/>
  </div>
</template>

<script>
import CuiLiveSearch from '@/components/LayoutComponents/Topbar/LiveSearch'
import CuiNotificationMenu from '@/components/LayoutComponents/Topbar/NotificationMenu'
import CuiProfileMenu from '@/components/LayoutComponents/Topbar/ProfileMenu'
import Updates from '@/components/LayoutComponents/Topbar/Updates'

export default {
  components: {
    CuiLiveSearch,
    CuiNotificationMenu,
    CuiProfileMenu,
    Updates,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
