import store from '@/store'
import router from '@/router'

export default {
  methods: {
    loadCount() {
      store.dispatch('notification/getCount')
    },
    setRead(ids, item = null) {
      return new Promise((resolve, reject) => {
        if (item !== null && item.is_seen_count === 0) {
          resolve()
          return false
        }
        this.loading = true
        store.dispatch('notification/delete', ids)
          .then(() => {
            this.loadCount()
          })
          .finally(() => {
            resolve()
            this.loading = false
          })
      })
    },
    goToNotification(path, item) {
      this.setRead([item.ntn_id], item)
        .then(() => {
          if (path === null) {
            if (item.user) {
              if (item.user.uer_type === 'AMR') {
                router.push({ name: 'user.admin.detail', params: { id: item.uer_id } }).catch(() => {
                })
              } else if (item.user.uer_type === 'RID') {
                router.push({ name: 'user.registered.detail', params: { id: item.uer_id } }).catch(() => {
                })
              }
            }
          } else {
            router.push({ path: path }).catch(() => {
            })
          }
        })
    },
    getLinkTo(item) {
      if (Number.isFinite(item.svy_id)) {
        return '/survey/trend/' + item.svy_id
      }
      if (Number.isFinite(item.rvw_id)) {
        return '/ecommerce/product/detail/' + item.review.put_id + '/reviews/' + item.rvw_id
      }
      if (Number.isFinite(item.odr_id)) {
        return '/ecommerce/order/detail/' + item.odr_id
      }
      if (Number.isFinite(item.cmt_id)) {
        if (Number.isFinite(item.comment.put_id)) {
          return '/ecommerce/product/detail/' + item.comment.put_id + '/comments/' + item.cmt_id
        } else {
          return '/blog/detail/' + item.comment.blg_id + '/comments/' + item.cmt_id
        }
      }
    },
  },
}
