import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/attributes', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => {
      return state.items
    },
  },
}
