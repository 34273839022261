import { $axios, dealWithErrors, dealWithSuccess /* dealWithSuccess */ } from '../import/http'
import store from '@/store'
import filter from '@/store/import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'id',
        type: 'int',
      }, {
        key: 'order_id',
        type: 'int',
      }, {
        key: 'created_at',
        type: 'date',
      }, {
        key: 'updated_at',
        type: 'date',
      }, {
        key: 'printed_labels_at',
        type: 'date',
      }, {
        key: 'ordered_shipment_at',
        type: 'date',
      }, {
        key: 'track_url',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    statuses: [{
      key: 'ADDED',
      text: 'Přidáno',
      color: 'blue',
    }, {
      key: 'PRINTED_LABELS',
      text: 'Tisk štítků',
      color: 'gray',
    }, {
      key: 'ORDERED_SHIPMENT',
      text: 'Objednáno ke svozu',
      color: 'orange',
    }, {
      key: 'DROPPED',
      text: 'Odstraněno',
      color: 'red',
    }],
    detail: null,
    shippers_ready_to_ship: [],
  },
  mutations: {
    SET_DETAIL(state, item) {
      state.item = item
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_SHIPPERS_READY_TO_SHIP(state, items) {
      state.shippers_ready_to_ship = items
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/balikobot-parcels', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getShippersReadyToShip({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/balikobot-parcels-shipment', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_SHIPPERS_READY_TO_SHIP', response.data.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    orderShipment({ commit }, data) {
      return new Promise((resolve, reject) => {
        $axios.post('/balikobot-parcels', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          dealWithSuccess({ message: 'OK!', description: 'Předání ke svozu proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    dropParcels({ commit }, data) {
      return new Promise((resolve, reject) => {
        $axios.post('/balikobot-parcels/drop', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          let ids = ''
          if (Array.isArray(response.data)) {
            ids = ' Objednávky: '
            if (response.data.length === 0) {
              ids += '---'
            } else {
              response.data.forEach(x => {
                ids += '#' + x + ', '
              })
              ids = ids.substring(0, ids.length - 2)
            }
          }
          dealWithSuccess({ message: 'OK!', description: 'Odebrání balíků proběhlo v pořádku.' + ids })
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    addParcels({ commit }, data) {
      return new Promise((resolve, reject) => {
        $axios.post('/balikobot-parcels/add', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          let ids = ''
          if (Array.isArray(response.data)) {
            ids = ' Objednávky: '
            if (response.data.length === 0) {
              ids += '---'
            } else {
              response.data.forEach(x => {
                ids += '#' + x + ', '
              })
              ids = ids.substring(0, ids.length - 2)
            }
          }
          dealWithSuccess({ message: 'OK!', description: 'Přidání balíků proběhlo v pořádku.' + ids })
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    overview({ commit }, data) {
      return new Promise((resolve, reject) => {
        $axios.post('/balikobot-parcels-overview', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    printLabels({ commit }, data) {
      return new Promise((resolve, reject) => {
        $axios.post('/balikobot-parcels-labels', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/balikobot-parcels/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    getDetail: state => {
      return state.detail
    },
    statuses: state => {
      return state.statuses
    },
    filteredItems: (state) => (searchText = '') => {
      const currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    shippersReadyToShip: state => state.shippers_ready_to_ship,
  },
}
