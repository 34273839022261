import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'mfe_id',
        type: 'int',
      }, {
        key: 'mfe_position',
        type: 'int',
      }, {
        key: 'mfe_is_blog',
        type: 'bool',
      }, {
        key: 'mfe_is_ecommerce_categories',
        type: 'bool',
      },
    ],
  },
  {
    path: 'languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'mfe_title',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      mfe_is_blog: null,
      mfe_is_ecommerce_categories: null,
      languages: [],
    },
    only: [],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index === -1) {
        state.items.push(items)
      } else {
        state.items.splice(index, 1)
        state.items.push(items)
      }
    },
    SET_CURRENT_ONLY(state, items) {
      const index = state.only.indexOf(state.only.find(x => x.lge_id === store.getters['language/active']))
      if (index === -1) {
        state.only.push(items)
      } else {
        state.only.splice(index, 1)
        state.only.push(items)
      }
    },
    SET_CURRENT_DETAIL(state, item) {
      state.detail.mfe_is_blog = item.mfe_is_blog
      state.detail.mfe_is_ecommerce_categories = item.mfe_is_ecommerce_categories
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      if (index === -1) {
        state.detail.languages.push({ lge_id: store.getters['language/active'], mfe_title: item.languages.length > 0 ? item.languages[0].pivot.mfe_title : '' })
      } else {
        state.detail.languages.splice(index, 1)
        state.detail.languages.push({ lge_id: store.getters['language/active'], mfe_title: item.languages.length > 0 ? item.languages[0].pivot.mfe_title : '' })
      }
    },
    DELETE(state, items) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          if (items.includes(value.data[i].mfe_id)) {
            state.items[key].data.splice(i, 1)
            i = i - 1
          }
        }
      })
    },
    SORT(state, items) {
      state.items.forEach(value => {
        value.data = value.data.sort(function(a, b) {
          return items.indexOf(a.mfe_id) - items.indexOf(b.mfe_id)
        })
      })
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/menu/footer-title/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOnly({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/menu/footer-title/only/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_ONLY', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/menu/footer-title/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/menu/footer-title', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { mfe_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putSort({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.put('/menu/footer-title', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SORT', items.map(x => x.mfe_id))
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Seřazení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/menu/footer-title', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/menu/footer-title/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageDetail: (state) => {
      return state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
    currentLanguageOnly: (state) => {
      const activeItems = state.only.filter(x => x.lge_id === store.getters['language/active'])
      return store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
    },
  },
}
