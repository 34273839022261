import { $axios, dealWithErrors, dealWithSuccess, dealWithWarning } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeysList = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'iae_id',
        type: 'int',
      }, {
        key: 'iae_format',
        type: 'string',
      }, {
        key: 'iae_name',
        type: 'string',
      }, {
        key: 'product_images_count',
        type: 'int',
      }, {
        key: 'manufacturers_count',
        type: 'int',
      }, {
        key: 'blogs_count',
        type: 'int',
      }, {
        key: 'categories_count',
        type: 'int',
      },
    ],
  },
  {
    path: 'image_category.',
    loop: false,
    keys: [
      {
        key: 'icy_name',
        type: 'string',
      },
    ],
  },
]
const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'iae_name',
        type: 'string',
      },
    ],
  },
]

function getSearchUrl(queryObj) {
  let url = '/image/search?page=' + queryObj.page
  if (queryObj.search !== '') {
    url += '&search=' + queryObj.search
  }
  if (queryObj.category_id !== undefined && queryObj.category_id !== null) {
    url += '&category_id=' + queryObj.category_id
  }
  return url
}

function getBreadcrumbUrl(queryObj) {
  let url = '/image/breadcrumbs?page=' + queryObj.page
  if (queryObj.search !== '') {
    url += '&search=' + queryObj.search
  }
  if (queryObj.category_id !== undefined && queryObj.category_id !== null) {
    url += '&category_id=' + queryObj.category_id
  }
  return url
}

const perPage = 12
const sizes = [{
  width: 1920,
  height: 920,
}, {
  width: 825,
  height: 550,
}, {
  width: 580,
  height: 580,
}, {
  width: 350,
  height: 300,
}, {
  width: 280,
  height: 180,
}, {
  width: 170,
  height: 170,
}, {
  width: 115,
  height: 115,
}, {
  width: 90,
  height: 70,
}, {
  width: 60,
  height: 60,
}]

export default {
  namespaced: true,
  state: {
    items: [],
    breadcrumbs: {},
    detail: {},
    searchedItems: {},
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_SEARCHED_ITEMS(state, { items, isChanged }) {
      if (!isChanged) {
        items.data = state.searchedItems.data.concat(items.data)
      }
      state.searchedItems = items
    },
    SET_BREADCRUMBS(state, { items, isChanged }) {
      if (!isChanged) {
        items.data = state.breadcrumbs.data.concat(items.data)
      }
      state.breadcrumbs = items
    },
    DELETE(state, items) {
      for (let i = 0; i < state.items.data.length; i++) {
        if (items.includes(state.items.data[i].iae_id)) {
          state.items.data.splice(i, 1)
          i = i - 1
        }
      }
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/image', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    search({ commit }, queryObj) {
      return new Promise((resolve, reject) => {
        $axios.get(getSearchUrl(queryObj), {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_SEARCHED_ITEMS', { items: response.data, isChanged: queryObj.changed })
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOnlyBreadcrumbs({ commit }, queryObj) {
      return new Promise((resolve, reject) => {
        $axios.get(getBreadcrumbUrl(queryObj), {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_BREADCRUMBS', { items: response.data, isChanged: queryObj.changed })
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/image/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/image', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { iae_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/image', items, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          resolve(response.data)
          if (response.data && response.data.status && response.data.status === 'error') {
            dealWithWarning({ message: 'Varování!', description: 'Některé položky se nepodařilo nahrát.' })
          } else {
            dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
          }
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/image/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    searchedItems: (state) => {
      return { count: state.searchedItems.total, data: state.searchedItems.data === undefined ? [] : state.searchedItems.data, per_page: perPage }
    },
    filteredItems: (state) => (param = { searchText: '', page: 1 }) => {
      let currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      if (param.searchText.length > 0) {
        currentItems = filter.methods.filterItems(currentItems, filteredKeys, param.searchText)
      }
      return { count: currentItems.length, data: currentItems.slice(0, param.page * perPage) }
    },
    searchedBreadcrumbItems: (state) => {
      return { count: state.breadcrumbs.total, data: state.breadcrumbs.data === undefined ? [] : state.breadcrumbs.data, per_page: perPage }
    },
    getImageById: (state) => (id) => {
      let currentItems = []
      if (state.searchedItems.data !== undefined) {
        currentItems = state.searchedItems.data
      } else if (state.items.length !== 0 && state.items.data !== undefined) {
        currentItems = state.items.data
      }
      return currentItems.find(x => x.iae_id === id)
    },
    getListFilteredItems: (state) => (searchText = '') => {
      const items = state.items.data !== undefined ? state.items.data : []
      if (searchText.length > 0) {
        return filter.methods.filterItems(items, filteredKeysList, searchText)
      }
      return items
    },
    getSizes: () => sizes,
    getDetail: (state) => {
      return state.detail
    },
  },
}
