<template>
  <a-drawer
    title="Nastavení firemních údajů"
    placement="right"
    :closable="true"
    :visible="company.isVisible"
    :zIndex="1009"
    @close="onClose"
    :width="settings.isMobileView || settings.isTabletView ? '80%' : 720"
  >
    <a-form :form="form" layout="vertical" @submit="handleSubmit">
      <a-form-item
        label="Název firmy"
        :validate-status="error('cin_name') ? 'error' : ''"
        :help="error('cin_name') || ''"
      >
        <a-input placeholder="Název firmy" v-decorator="['cin_name', {rules: [{max: 50, message: 'Název firmy nemůže být delší než 50 znaků!'}, {required: true, message: 'Název firmy musí být vyplněn!'}]}]"/>
      </a-form-item>

      <a-form-item
        label="CEO"
        :validate-status="error('cin_ceo') ? 'error' : ''"
        :help="error('cin_ceo') || ''"
      >
        <a-input placeholder="CEO" v-decorator="['cin_ceo', {rules: [{max: 50, message: 'CEO nemůže být delší než 50 znaků!'}, {required: true, message: 'CEO musí být vyplněn!'}]}]"/>
      </a-form-item>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item
            label="Kontaktní e-mail"
            :validate-status="error('cin_email') ? 'error' : ''"
            :help="error('cin_email') || ''"
          >
            <a-input placeholder="Kontaktní e-mail" v-decorator="['cin_email', {rules: [{max: 100, message: 'Kontaktní e-mail nemůže být delší než 100 znaků!'}, {required: true, message: 'Kontaktní e-mail musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Telefon"
            :validate-status="error('cin_telephone_number') ? 'error' : ''"
            :help="error('cin_telephone_number') || ''"
          >
            <a-input placeholder="Telefon" v-decorator="['cin_telephone_number', {rules: [{max: 20, message: 'Telefon nemůže být delší než 20 znaků!'}, {required: true, message: 'Telefon musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item
            label="Ulice a č.p."
            :validate-status="error('cin_street') ? 'error' : ''"
            :help="error('cin_street') || ''"
          >
            <a-input placeholder="Ulice a č.p." v-decorator="['cin_street', {rules: [{max: 60, message: 'Ulice a č.p. nemůže být delší než 60 znaků!'}, {required: true, message: 'Ulice a č.p. musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Město"
            :validate-status="error('cin_city') ? 'error' : ''"
            :help="error('cin_city') || ''"
          >
            <a-input placeholder="Město" v-decorator="['cin_city', {rules: [{max: 60, message: 'Město nemůže být delší než 60 znaků!'}, {required: true, message: 'Město musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item
            label="PSČ"
            :validate-status="error('cin_zip') ? 'error' : ''"
            :help="error('cin_zip') || ''"
          >
            <a-input placeholder="PSČ" v-decorator="['cin_zip', {rules: [{len: 5, message: 'PSČ musí mít 5 znaků!'}, {required: true, message: 'PSČ musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Země"
            :validate-status="error('cin_country') ? 'error' : ''"
            :help="error('cin_country') || ''"
          >
            <a-input placeholder="Země" v-decorator="['cin_country', {rules: [{max: 60, message: 'Země nemůže být delší než 60 znaků!'}, {required: true, message: 'Země musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item
            label="IČ"
            :validate-status="error('cin_tin') ? 'error' : ''"
            :help="error('cin_tin') || ''"
          >
            <a-input placeholder="IČ" v-decorator="['cin_tin', {rules: [{max: 20, message: 'IČ nemůže být delší než 20 znaků!'}, {required: true, message: 'IČ musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="DIČ"
            :validate-status="error('cin_vatin') ? 'error' : ''"
            :help="error('cin_vatin') || ''"
          >
            <a-input placeholder="DIČ" v-decorator="['cin_vatin', {rules: [{max: 20, message: 'DIČ nemůže být delší než 20 znaků!'}, {required: true, message: 'DIČ musí být vyplněn!'}]}]"/>
          </a-form-item>
        </a-col>
      </a-row>
      <div
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }"
      >
        <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
      </div>
    </a-form>
  </a-drawer>
</template>

<script>
function hasErrors (fieldsError) {
  let yes = false
  Object.keys(fieldsError).some((value) => {
    if (fieldsError[value] !== undefined) {
      fieldsError[value].some((someValue) => {
        if (someValue !== undefined && (typeof someValue === 'string' || Array.isArray(someValue))) {
          yes = true
          return true
        }
      })
    }
  })
  return yes
}

export default {
  name: 'my-company-company',
  props: {
    settings: Object,
    company: Object,
  },
  data() {
    return {
      hasErrors,
      loading: false,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    onClose() {
      this.$store.commit('companyInformation/SET_VISIBILITY', !this.company.isVisible)
    },
    initDetail() {
      this.$nprogress.start()
      this.loading = true
      this.$store.dispatch('companyInformation/getOne')
        .then(() => {
          this.form.setFieldsValue({
            cin_name: this.company.item.cin_name,
            cin_ceo: this.company.item.cin_ceo,
            cin_zip: this.company.item.cin_zip,
            cin_city: this.company.item.cin_city,
            cin_street: this.company.item.cin_street,
            cin_email: this.company.item.cin_email,
            cin_telephone_number: this.company.item.cin_telephone_number,
            cin_country: this.company.item.cin_country,
            cin_vatin: this.company.item.cin_vatin,
            cin_tin: this.company.item.cin_tin,
          })
        })
        .catch(error => {
          let errorBag
          if (error.response === undefined) {
            errorBag = {
              message: 'ERROR 520: Něco se pokazilo',
              description: error,
            }
          } else if (error.response.data === undefined) {
            errorBag = {
              message: 'ERROR ' + error.response.status + ': Něco se pokazilo',
              description: error.response,
            }
          } else {
            let description = ''
            for (const errorKey in error.response.data.errors) {
              description += error.response.data.errors[errorKey]
            }
            errorBag = {
              message: 'ERROR ' + error.response.status + ': ' + error.response.data.message,
              description: description,
            }
          }
          this.$notification.error(errorBag)
        })
        .finally(() => {
          this.$nprogress.done()
          this.loading = false
        })
    },
    getData() {
      return {
        cin_name: this.form.getFieldValue('cin_name'),
        cin_ceo: this.form.getFieldValue('cin_ceo'),
        cin_tin: this.form.getFieldValue('cin_tin'),
        cin_vatin: this.form.getFieldValue('cin_vatin'),
        cin_email: this.form.getFieldValue('cin_email'),
        cin_telephone_number: this.form.getFieldValue('cin_telephone_number'),
        cin_street: this.form.getFieldValue('cin_street'),
        cin_zip: this.form.getFieldValue('cin_zip'),
        cin_country: this.form.getFieldValue('cin_country'),
        cin_city: this.form.getFieldValue('cin_city'),
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$nprogress.start()
          this.$store.dispatch('companyInformation/put', this.getData())
            .then((response) => {
              this.$notification.success({
                message: response.message,
                description: response.description,
              })
            })
            .catch((error) => {
              let errorBag
              if (error.response.data === undefined) {
                errorBag = {
                  message: 'ERROR ' + error.response.status + ': Něco se pokazilo',
                  description: error.response,
                }
              } else {
                let description = ''
                for (const errorKey in error.response.data.errors) {
                  description += error.response.data.errors[errorKey]
                }
                errorBag = {
                  message: 'ERROR ' + error.response.status + ': ' + error.response.data.message,
                  description: description,
                }
              }
              this.$notification.error(errorBag)
            })
            .finally(() => {
              this.$nprogress.done()
              this.loading = false
            })
        }
      })
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  created() {
    if (this.company.isVisible) {
      this.initDetail()
    }
  },
  watch: {
    company(newValue, oldValue) {
      if (newValue.isVisible && newValue.isVisible !== oldValue.isVisible) {
        this.initDetail()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
