export const getSearchAdminMenu = [
  {
    title: 'Můj účet',
    value: 'Můj účet',
    key: 'profile',
    url: '/profile',
  },
  {
    title: 'Nástěnka',
    value: 'Nástěnka',
    key: 'dashboard',
    url: '/dashboard',
  },
  {
    title: 'Články',
    value: 'Články',
    key: 'article',
    parentUrl: '/article/type/dte',
    children: [
      {
        title: 'Přidat',
        value: 'Články Přidat',
        key: 'articleAdd',
        url: '/article/add',
      },
      {
        title: 'Seznam',
        value: 'Články Seznam',
        key: 'articleList',
        url: '/article/type/dte',
      },
      {
        title: 'Typy',
        value: 'Články Typy',
        key: 'articleType',
        selectable: false,
        children: [
          {
            title: 'Často kladené otázky',
            value: 'Články Typy Často kladené otázky',
            key: 'articleFaq',
            parentUrl: '/article/type/faq',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Často kladené otázky seznam',
                key: 'articleFaqList',
                url: '/article/type/faq',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Často kladené otázky přidat',
                key: 'articleFaqAdd',
                url: '/article/type/faq/add',
              },
            ],
          },
          {
            title: 'Galerie',
            value: 'Články Typy Galerie',
            key: 'articleGallery',
            parentUrl: '/article/type/gly',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Galerie Seznam',
                key: 'articleGalleryList',
                url: '/article/type/gly',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Galerie Přidat',
                key: 'articleGalleryAdd',
                url: '/article/type/gly/add',
              },
            ],
          },
          {
            title: 'Kdo jsme',
            value: 'Články Typy Kdo jsme',
            key: 'articleWhoWeAre',
            parentUrl: '/article/type/wie',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Kdo jsme Seznam',
                key: 'articleWhoWeAreList',
                url: '/article/type/wie',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Kdo jsme Přidat',
                key: 'articleWhoWeAreAdd',
                url: '/article/type/wie/add',
              },
            ],
          },
          {
            title: 'Krok',
            value: 'Články Typy Krok',
            key: 'articleStep',
            parentUrl: '/article/type/stp',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Krok Seznam',
                key: 'articleStepList',
                url: '/article/type/stp',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Krok Přidat',
                key: 'articleStepAdd',
                url: '/article/type/stp/add',
              },
            ],
          },
          {
            title: 'Kontakt',
            value: 'Články Typy Kontakt',
            key: 'articleContact',
            parentUrl: '/article/type/ctt',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Kontakt Seznam',
                key: 'articleContactList',
                url: '/article/type/ctt',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Kontakt Přidat',
                key: 'articleContactAdd',
                url: '/article/type/ctt/add',
              },
            ],
          },
          {
            title: 'Nabídka',
            value: 'Články Typy Nabídka',
            key: 'articleOffer',
            parentUrl: '/article/type/doy',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Nabídka Seznam',
                key: 'articleOfferList',
                url: '/article/type/doy',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Nabídka Přidat',
                key: 'articleOfferAdd',
                url: '/article/type/doy/add',
              },
            ],
          },
          {
            title: 'Nabízené služby',
            value: 'Články Typy Nabízené služby',
            key: 'articleFeatures',
            parentUrl: '/article/type/cfe',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Nabízené služby Seznam',
                key: 'articleFeaturesList',
                url: '/article/type/cfe',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Nabízené služby Přidat',
                key: 'articleFeaturesAdd',
                url: '/article/type/cfe/add',
              },
            ],
          },
          {
            title: 'O nás',
            value: 'Články Typy O nás',
            key: 'articleAboutUs',
            parentUrl: '/article/type/aus',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy O nás Seznam',
                key: 'articleAboutUsList',
                url: '/article/type/aus',
              },
              {
                title: 'Přidat',
                value: 'Články Typy O nás Přidat',
                key: 'articleAboutUsAdd',
                url: '/article/type/aus/add',
              },
            ],
          },
          {
            title: 'Reference',
            value: 'Články Typy Reference',
            key: 'articleTestimonials',
            parentUrl: '/article/type/ttl',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Reference Seznam',
                key: 'articleTestimonialsList',
                url: '/article/type/ttl',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Reference Přidat',
                key: 'articleTestimonialsAdd',
                url: '/article/type/ttl/add',
              },
            ],
          },
          {
            title: 'Slide',
            value: 'Články Typy Slide',
            key: 'articleSlide',
            parentUrl: '/article/type/sle',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Slide Seznam',
                key: 'articleSlideList',
                url: '/article/type/sle',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Slide Přidat',
                key: 'articleSlideAdd',
                url: '/article/type/sle/add',
              },
            ],
          },
          {
            title: 'Tým',
            value: 'Články Typy Tým',
            key: 'articleTeam',
            parentUrl: '/article/type/mom',
            children: [
              {
                title: 'Seznam',
                value: 'Články Typy Tým Seznam',
                key: 'articleTeamList',
                url: '/article/type/mom',
              },
              {
                title: 'Přidat',
                value: 'Články Typy Tým Přidat',
                key: 'articleTeamAdd',
                url: '/article/type/mom/add',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Blog',
    value: 'Blog',
    key: 'blog',
    parentUrl: '/blog',
    children: [
      {
        title: 'Přidat',
        value: 'Blog Přidat',
        key: 'blogAdd',
        url: '/blog/add',
      },
      {
        title: 'Seznam',
        value: 'Blog Seznam',
        key: 'blogList',
        url: '/blog',
      },
      {
        title: 'Statistiky',
        value: 'Blog Statistiky',
        key: 'blogStats',
        url: '/blog/stats',
        disabled: true,
      },
      {
        title: 'Kategorie',
        value: 'Blog Kategorie',
        key: 'blogCategory',
        parentUrl: '/blog/category',
        children: [
          {
            title: 'Přidat',
            value: 'Blog Kategorie Přidat',
            key: 'blogCategoryAdd',
            url: '/blog/category/add',
          },
          {
            title: 'Seřadit',
            value: 'Blog Kategorie Seřadit',
            key: 'blogCategorySort',
            url: '/blog/category/sort',
          },
          {
            title: 'Seznam',
            value: 'Blog Kategorie Seznam',
            key: 'blogCategoryList',
            url: '/blog/category',
          },
        ],
      },
      {
        title: 'Tagy',
        value: 'Blog Tagy',
        key: 'blogTag',
        parentUrl: '/blog/tag',
        children: [
          {
            title: 'Přidat',
            value: 'Blog Tagy Přidat',
            key: 'blogTagAdd',
            url: '/blog/tag/add',
          },
          {
            title: 'Seznam',
            value: 'Blog Tagy Seznam',
            key: 'blogTagList',
            url: '/blog/tag',
          },
        ],
      },
    ],
  },
  {
    title: 'E-shop',
    value: 'E-shop',
    key: 'ecommerce',
    selectable: false,
    children:
      [
        {
          title: 'Dopravy',
          value: 'E-shop Dopravy',
          key: 'ecommerceDelivery',
          parentUrl: '/ecommerce/delivery',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Dopravy Přidat',
              key: 'ecommerceDeliveryAdd',
              url: '/ecommerce/delivery/add',
            },
            {
              title: 'Seznam',
              value: 'E-shop Dopravy Seznam',
              key: 'ecommerceDeliveryList',
              url: '/ecommerce/delivery',
            },
          ],
        },
        {
          title: 'Kategorie',
          value: 'E-shop Kategorie',
          key: 'ecommerceCategory',
          parentUrl: '/ecommerce/category',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Kategorie Přidat',
              key: 'ecommerceCategoryAdd',
              url: '/ecommerce/category/add',
            },
            {
              title: 'Seřadit',
              value: 'E-shop Kategorie Seřadit',
              key: 'ecommerceCategorySort',
              url: '/ecommerce/category/sort',
            },
            {
              title: 'Seznam',
              value: 'E-shop Kategorie Seznam',
              key: 'ecommerceCategoryList',
              url: '/ecommerce/category',
            },
          ],
        },
        {
          title: 'Štítky',
          value: 'E-shop Štítky',
          key: 'ecommerceSticker',
          parentUrl: '/ecommerce/sticker',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Štítky Přidat',
              key: 'ecommerceStickerAdd',
              url: '/ecommerce/sticker/add',
            },
            {
              title: 'Seřadit',
              value: 'E-shop Štítky Seřadit',
              key: 'ecommerceStickerSort',
              url: '/ecommerce/sticker/sort',
            },
            {
              title: 'Seznam',
              value: 'E-shop Štítky Seznam',
              key: 'ecommerceStickerList',
              url: '/ecommerce/sticker',
            },
          ],
        },
        {
          title: 'Objednávky',
          value: 'E-shop Objednávky',
          key: 'ecommerceOrder',
          parentUrl: '/ecommerce/order',
          url: '/ecommerce/order',
        },
        {
          title: 'Parametry',
          value: 'E-shop Parametry',
          key: 'ecommerceParameter',
          parentUrl: '/ecommerce/parameter',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Parametry Přidat',
              key: 'ecommerceParameterAdd',
              url: '/ecommerce/parameter/add',
            },
            {
              title: 'Seznam',
              value: 'E-shop Parametry Seznam',
              key: 'ecommerceParameterList',
              url: '/ecommerce/parameter',
            },
          ],
        },
        {
          title: 'Platby',
          value: 'E-shop Platby',
          key: 'ecommercePayment',
          parentUrl: '/ecommerce/payment',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Platby Přidat',
              key: 'ecommercePaymentAdd',
              url: '/ecommerce/payment/add',
            },
            {
              title: 'Seznam',
              value: 'E-shop Platby Seznam',
              key: 'ecommercePaymentList',
              url: '/ecommerce/payment',
            },
          ],
        },
        {
          title: 'Produkty',
          value: 'E-shop Produkty',
          key: 'ecommerceProduct',
          parentUrl: '/ecommerce/product/',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Produkty Přidat',
              key: 'ecommerceProductAdd',
              url: '/ecommerce/product/add',
            },
            {
              title: 'Seznam',
              value: 'E-shop Produkty Seznam',
              key: 'ecommerceProductList',
              url: '/ecommerce/product/',
            },
          ],
        },
        {
          title: 'Statistiky',
          value: 'E-shop Statistiky',
          key: 'ecommerceStats',
          url: '/ecommerce/stats',
          disabled: true,
        },
        {
          title: 'Výrobci',
          value: 'E-shop Výrobci',
          key: 'ecommerceManufacturer',
          parentUrl: '/ecommerce/manufacturer',
          children: [
            {
              title: 'Přidat',
              value: 'E-shop Výrobci Přidat',
              key: 'ecommerceManufacturerAdd',
              url: '/ecommerce/manufacturer/add',
            },
            {
              title: 'Seznam',
              value: 'E-shop Výrobci Seznam',
              key: 'ecommerceManufacturerList',
              url: '/ecommerce/manufacturer',
            },
          ],
        },
      ],
  },
  {
    title: 'Obrázky',
    value: 'Obrázky',
    key: 'image',
    parentUrl: '/image',
    children: [
      {
        title: 'Přidat',
        value: 'Obrázky Přidat',
        key: 'imageAdd',
        url: '/image/add',
      },
      {
        title: 'Seznam',
        value: 'Obrázky Seznam',
        key: 'imageList',
        url: '/image',
      },
      {
        title: 'Kategorie',
        value: 'Obrázky Kategorie',
        key: 'imageCategory',
        parentUrl: '/image/category',
        children: [
          {
            title: 'Přidat',
            value: 'Obrázky Kategorie Přidat',
            key: 'imageCategoryAdd',
            url: '/image/category/add',
          },
          {
            title: 'Seznam',
            value: 'Obrázky Kategorie Seznam',
            key: 'imageCategoryList',
            url: '/image/category',
          },
        ],
      },
    ],
  },
  {
    title: 'Skupiny',
    value: 'Skupiny',
    key: 'group',
    parentUrl: '/group',
    children: [
      {
        title: 'Přidat',
        value: 'Skupiny Přidat',
        key: 'groupAdd',
        url: '/group/add',
      },
      {
        title: 'Seznam',
        value: 'Skupiny Seznam',
        key: 'groupList',
        url: '/group',
      },
    ],
  },
  {
    title: 'Soubory',
    value: 'Soubory',
    key: 'file',
    parentUrl: '/file',
    children: [
      {
        title: 'Přidat',
        value: 'Soubory Přidat',
        key: 'fileAdd',
        url: '/file/add',
      },
      {
        title: 'Seznam',
        value: 'Soubory Seznam',
        key: 'fileList',
        url: '/file',
      },
      {
        title: 'Kategorie',
        value: 'Soubory Kategorie',
        key: 'fileCategory',
        parentUrl: '/file/category',
        children: [
          {
            title: 'Přidat',
            value: 'Soubory Kategorie Přidat',
            key: 'fileCategoryAdd',
            url: '/file/category/add',
          },
          {
            title: 'Seznam',
            value: 'Soubory Kategorie Seznam',
            key: 'fileCategoryList',
            url: '/file/category',
          },
        ],
      },
    ],
  },
  {
    title: 'Menu',
    value: 'Menu',
    key: 'menu',
    selectable: false,
    children: [
      {
        title: 'Hlavní',
        value: 'Menu Hlavní',
        key: 'menuMain',
        parentUrl: '/menu/main',
        children: [
          {
            title: 'Přidat',
            value: 'Menu Hlavní Přidat',
            key: 'menuMainAdd',
            url: '/menu/main/add',
          },
          {
            title: 'Seřadit',
            value: 'Menu Hlavní Seřadit',
            key: 'menuMainSort',
            url: '/menu/main/sort',
          },
          {
            title: 'Seznam',
            value: 'Menu Hlavní Seznam',
            key: 'menuMainList',
            url: '/menu/main',
          },
        ],
      },
      {
        title: 'Zápatí',
        value: 'Menu Zápatí',
        key: 'menuFooter',
        parentUrl: '/menu/footer',
        children: [
          {
            title: 'Přidat',
            value: 'Menu Zápatí Přidat',
            key: 'menuFooterAdd',
            url: '/menu/footer/add',
          },
          {
            title: 'Seřadit',
            value: 'Menu Zápatí Seřadit',
            key: 'menuFooterSort',
            url: '/menu/footer/sort',
          },
          {
            title: 'Seznam',
            value: 'Menu Zápatí Seznam',
            key: 'menuFooterList',
            url: '/menu/footer',
          },
          {
            title: 'Kategorie',
            value: 'Menu Zápatí Kategorie',
            key: 'menuFooterTitle',
            parentUrl: '/menu/footer/category',
            children: [
              {
                title: 'Přidat',
                value: 'Menu Zápatí Kategorie Přidat',
                key: 'menuFooterTitleAdd',
                url: '/menu/footer/category/add',
              },
              {
                title: 'Seřadit',
                value: 'Menu Zápatí Kategorie Seřadit',
                key: 'menuFooterTitleSort',
                url: '/menu/footer/category/sort',
              },
              {
                title: 'Seznam',
                value: 'Menu Zápatí Kategorie Seznam',
                key: 'menuFooterTitleList',
                url: '/menu/footer/category',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Meta Keywords',
    value: 'Meta Keywords',
    key: 'metaKeywords',
    parentUrl: '/meta-keywords',
    children: [
      {
        title: 'Přidat',
        value: 'Meta Keywords Přidat',
        key: 'metaKeywordsAdd',
        url: '/meta-keywords/add',
      },
      {
        title: 'Seznam',
        value: 'Meta Keywords Seznam',
        key: 'metaKeywordsList',
        url: '/meta-keywords',
      },
    ],
  },
  {
    title: 'Dotazníky',
    value: 'Dotazníky',
    key: 'survey',
    parentUrl: '/survey',
    children: [
      {
        title: 'Přidat',
        value: 'Dotazníky Přidat',
        key: 'surveyAdd',
        url: '/survey/add',
      },
      {
        title: 'Seznam',
        value: 'Dotazníky Seznam',
        key: 'surveyList',
        url: '/survey',
      },
    ],
  },
  {
    title: 'Uživatelé',
    value: 'Uživatelé',
    key: 'user',
    selectable: false,
    children: [
      {
        title: 'Administrátoři',
        value: 'Uživatelé Administrátoři',
        key: 'userAdmin',
        parentUrl: '/user/admin',
        children: [
          {
            title: 'Přidat',
            value: 'Uživatelé Administrátoři Přidat',
            key: 'userAdminAdd',
            url: '/user/admin/add',
          },
          {
            title: 'Seznam',
            value: 'Uživatelé Administrátoři Seznam',
            key: 'userAdminList',
            url: '/user/admin',
          },
        ],
      },
      {
        title: 'Zákazníci',
        value: 'Uživatelé Zákazníci',
        key: 'userRegistered',
        parentUrl: '/user/registered',
        children: [
          {
            title: 'Seznam',
            value: 'Uživatelé Zákazníci Seznam',
            key: 'userRegisteredList',
            url: '/user/registered',
          },
        ],
      },
    ],
  },
]
