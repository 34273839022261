import store from '@/store'
import { $axios } from '../store/import/http'

export default {
  install: (Vue, options) => {
    Vue.prototype.$auth = {
      login: async (username, pass) => {
        return $axios.post('/auth/login', {
          username: username,
          password: pass,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
      },
      logout: async () => {
        await $axios.post('/auth/logout', null,
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + store.state.token.access_token,
            },
          })
      },
    }
  },
}
