<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge>
        <a-avatar shape="square" :src="user && user.avatar !== null ? $avatarVectorPath + user.avatar : ''" icon="user" size="large" :class="[$style['avatar'], $style['profile-pic']]"/>
      </a-badge>
    </div>
    <a-menu slot="overlay">
      <a-menu-item v-if="user !== null">
        <div>
          <strong>{{ user.uer_first_name }} {{ user.uer_last_name }}</strong>
        </div>
        <div>
          <strong class="mr-1">{{ user.email }}</strong>
        </div>
      </a-menu-item>
      <a-menu-divider/>
      <a-menu-item>
        <router-link to="/profile">
          <a-icon :class="$style.menuIcon" type="user"></a-icon> Upravit účet
        </router-link>
      </a-menu-item>
      <a-menu-item>
        <a href="javascript: void(0);" @click="logout">
          <a-icon :class="$style.menuIcon" type="logout"></a-icon> Odhlásit se
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import router from '@/router'
import { dealWithErrors, dealWithSuccess } from '../../../../store/import/http'

export default {
  computed: {
    user: function() {
      return this.$store.getters['auth/getItem']
    },
  },
  methods: {
    logout(e) {
      e.preventDefault()
      this.$auth.logout()
        .then(() => {
          const token = null
          this.$store.commit('UPDATE_ACCESS_TOKEN', { token })
          this.$store.commit('SET_REFRESH_TOKEN', { token })
          router.push('/auth/login')
          localStorage.clear()
          sessionStorage.clear()
          dealWithSuccess({
            message: 'Byl jste odhlášen',
            description: 'Úspěšně jste se odhlásil z administrace!',
          })
        })
        .catch(error => {
          dealWithErrors(error, false)
        })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
