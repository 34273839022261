import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'grp_id',
        type: 'int',
      },
      {
        key: 'grp_name',
        type: 'string',
      },
      {
        key: 'grp_is_default',
        type: 'bool',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    active: [],
    detail: {},
    privateGroups: {},
    publicGroups: {},
  },
  mutations: {
    SET_PRIVATE(state, items) {
      state.privateGroups = items
    },
    SET_PUBLIC(state, items) {
      state.publicGroups = items
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_ACTIVE(state, active) {
      state.active = active
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
    UPDATE_STATE(state, payload) {
      state.items.data.forEach((value, key) => {
        if (payload.items.includes(value.grp_id)) {
          value.grp_is_active = payload.activated
        }
      })
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/group', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/group/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getActive({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/group?expand=active', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ACTIVE', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getPrivateGroups({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/group/private', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_PRIVATE', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getPublicGroups({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/group/public', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_PUBLIC', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    deactivate({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/group', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { grp_id: x } }) },
        }).then(() => {
          commit('UPDATE_STATE', { items: items, activated: false })
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    activate({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.patch('/group/' + id, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('UPDATE_STATE', { items: id, activated: true })
          resolve()
          dealWithSuccess({ data: response.data.data, message: 'OK!', description: 'Aktivace proběhla v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/group/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/group', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filteredItems: (state) => (searchText = '') => {
      const currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    getPrivateGroups: (state) => {
      return state.privateGroups.data === undefined ? [] : state.privateGroups.data
    },
    getPublicGroups: (state) => {
      return state.publicGroups.data === undefined ? [] : state.publicGroups.data
    },
    active: (state) => {
      return state.active.length !== 0 && state.active.data !== undefined ? state.active.data : []
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
