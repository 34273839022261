<template>
  <div>
    Uživatel
    <a v-if="item.uer_id !== null" href="javascript:;" @click="goToNotification(null, item)">
      <b>{{ item.user === null || item.user.uer_first_name === null ? '#' + item.uer_id : item.user.uer_first_name + ' ' + item.user.uer_last_name }}</b>
    </a>
    {{ notificationSettings.find(x => x.type === item.ntn_type).message }}
    <a href="javascript:;" @click="goToNotification(getLinkTo(item), item)"
       v-if="item.svy_id !== null || item.rvw_id !== null || item.odr_id !== null || item.cmt_id !== null">
      <b>#{{ item.svy_id || item.rvw_id || item.odr_id || item.cmt_id }}</b>
    </a>
  </div>
</template>

<script>
import notificationMixin from '@/services/notificationMixin'
import store from '@/store'

export default {
  name: 'MyDesc',
  mixins: [notificationMixin],
  props: {
    item: Object,
  },
  computed: {
    notificationSettings: function () {
      return store.state.notification.settings
    },
  },
}
</script>

<style scoped>

</style>
