import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import { setStoreGroups } from '@/store/import/storeGroups'

export default {
  namespaced: true,
  state: {
    active: localStorage.getItem('__category__active') || null,
    items: [],
    urls: [],
    detail: {
      languages: [],
      public_groups: [],
      private_groups: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_ACTIVE(state, ceyId) {
      state.active = ceyId
      localStorage.setItem('__category__active', ceyId)
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    SET_CURRENT_URLS(state, items) {
      const index = state.urls.indexOf(state.urls.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.urls.splice(index, 1)
      }
      state.urls.push(items)
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.languages
      state.detail = Object.assign({}, item)
      setStoreGroups(item, state, 'gct_discount', 'group_category_discounts')
      state.detail.languages = oldLang
      if (index !== -1) {
        state.detail.languages.splice(index, 1)
      }
      state.detail.languages.push({
        lge_id: store.getters['language/active'],
        cle_name: item.languages.length > 0 ? item.languages[0].pivot.cle_name : undefined,
        cle_url: item.languages.length > 0 ? item.languages[0].pivot.cle_url : undefined,
        self_url: item.languages.length > 0 ? item.languages[0].pivot.self_url : undefined,
        cle_description: item.languages.length > 0 ? item.languages[0].pivot.cle_description : undefined,
      })
    },
    DELETE(state) {
      state.items = []
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/category/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getWithAttributes({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/category/attributes/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_URLS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/category/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/category', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { cey_id: x } }) },
        }).then(() => {
          commit('DELETE')
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putSort({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.put('/category', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SET_ITEMS', [])
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Seřazení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/category/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/category', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      return store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
    },
    currentLanguageAttributes: (state) => {
      const activeItems = state.urls.filter(x => x.lge_id === store.getters['language/active'])
      return store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
    },
    currentLanguageDetail: (state) => {
      return state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
    getActive: (state) => {
      return state.active
    },
  },
}
