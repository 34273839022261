import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Clipboard from 'v-clipboard'
import auth from './services/auth'
import store from './store'
import NProgress from 'vue-nprogress'
import './registerServiceWorker'
import './global.scss'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
import Ckeditor from '@ckeditor/ckeditor5-vue2'
import VueLazyload from 'vue-lazyload'
import {
  AutoComplete, Empty, List, Timeline, ConfigProvider, Card, Avatar, DatePicker, TreeSelect, Tree, Rate, Breadcrumb, InputNumber, Steps, Message,
  Upload, Button, Layout, Table, Icon, Progress, Radio, Dropdown, Menu, Row, Col,
  Carousel, Input, Calendar, Badge, Modal, Slider, Form, Tooltip, Select, Switch, Result,
  Tag, Affix, Spin, Alert, Checkbox, Tabs, Pagination, notification, Popconfirm, Popover, Drawer, LocaleProvider,
} from 'ant-design-vue'
Vue.config.ignoredElements = [/^ion-/]
Vue.use(AutoComplete)
Vue.use(Avatar)
Vue.use(VueLazyload, {
  error: 'resources/images/warning.svg',
  loading: 'resources/images/loading.gif',
})
Vue.use(Ckeditor)
Vue.use(Clipboard)
Vue.use(Modal)
Vue.use(Vue2Filters, {
  currency: {
    symbol: process.env.VUE_APP_CURRENCY,
    decimalDigits: 2,
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
  },
  number: {
    thousandsSeparator: ' ',
    decimalSeparator: ',',
  },
})
Vue.use(Button)
Vue.use(Rate)
Vue.use(Card)
Vue.use(DatePicker)
Vue.use(Empty)
Vue.use(TreeSelect)
Vue.use(List)
Vue.use(Timeline)
Vue.use(ConfigProvider)
Vue.use(Result)
Vue.use(LocaleProvider)
Vue.use(Breadcrumb)
Vue.use(Layout)
Vue.use(Table)
Vue.use(Icon)
Vue.use(Progress)
Vue.use(Radio)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Carousel)
Vue.use(Input)
Vue.use(Calendar)
Vue.use(Badge)
Vue.use(Slider)
Vue.use(Form)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Tag)
Vue.use(Affix)
Vue.use(Spin)
Vue.use(Alert)
Vue.use(Checkbox)
Vue.use(Tabs)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(Upload)
Vue.use(Message)
Vue.use(Popconfirm)
Vue.use(Popover)
Vue.use(Steps)
Vue.use(InputNumber)
Vue.use(Drawer)
Vue.use(Switch)
Vue.use(notification)
Vue.use(auth)
Vue.use(NProgress)
moment.locale('cs')
Vue.prototype.$notification = notification
Vue.prototype.$pageSize = 10
Vue.prototype.$modal = Modal
Vue.prototype.$message = Message
Vue.prototype.$moment = moment
Vue.prototype.$uploadImagePath = process.env.VUE_APP_STORAGE_UPLOADS_IMAGES
Vue.prototype.$appName = process.env.VUE_APP_NAME
Vue.prototype.$companyLogoPath = '/resources/images/kominexpres_logo.svg'
Vue.prototype.$feedPath = process.env.VUE_APP_STORAGE_FEEDS
Vue.prototype.$feedGoogle = process.env.VUE_APP_FEED_GOOGLE
Vue.prototype.$feedHeureka = process.env.VUE_APP_FEED_HEUREKA
Vue.prototype.$feedZbozi = process.env.VUE_APP_FEED_ZBOZI
Vue.prototype.$currency = process.env.VUE_APP_CURRENCY
Vue.prototype.$appVersion = process.env.VUE_APP_VERSION
Vue.prototype.$ceskaPostaDetailUrl = process.env.VUE_APP_CESKA_POSTA_DETAIL_URL
Vue.prototype.$uploadFilePath = process.env.VUE_APP_STORAGE_UPLOADS_FILES
Vue.prototype.$avatarVectorPath = process.env.VUE_APP_AVATAR_VECTOR_FOLDER
Vue.prototype.$avatarVectors = [
  'boy-blue-shirt.svg',
  'boy-brown-shirt.svg',
  'boy-grey-shirt.svg',
  'boy-red-shirt.svg',
  'boy-yellow-shirt.svg',
  'girl-blue-shirt.svg',
  'girl-grey-shirt.svg',
  'girl-purple-shirt.svg',
  'girl-red-shirt.svg',
  'girl-wine-shirt.svg',
]
Vue.config.productionTip = false

const nprogress = new NProgress({ parent: 'body' })

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).utc().local().format('D. MMMM YYYY v HH:mm:ss')
  }
})
Vue.filter('formatOutputDate', function(value) {
  if (value) {
    return moment(String(value)).utc().local().format('YYYY/MM/DD HH:mm:ss')
  }
})
Vue.filter('fromNowDate', function(value) {
  if (value) {
    return moment.utc(new Date(String(value))).local().fromNow(true)
  }
})

new Vue({
  router,
  store,
  nprogress,
  render: h => h(App),
}).$mount('#app')
