import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
    detail: null,
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT_DETAIL(state, item) {
      state.detail = item
    },
    DELETE(state, items) {},
  },
  actions: {
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/review/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          error = dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/review', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { rvw_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    items: state => state.items,
    getDetail: state => state.detail,
  },
}
