<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <router-link v-if="activeItem.url !== '/dashboard'" to="/dashboard" class="text-muted">Nástěnka</router-link>
      <template v-for="(item, index) in reverseItems">
        <span v-if="index !== breadcrumb.length-1" :key="index">
          <span :class="$style.arrow" class="text-muted"></span>
          <router-link v-if="item.url || item.parentUrl" :to="item.url ? item.url : item.parentUrl" class="text-muted">{{item.title}}</router-link>
          <strong v-else class="text-muted font-weight-normal">{{item.title}}</strong>
        </span>
      </template>
      <span v-if="activeItem">
        <span v-if="activeItem.url !== '/dashboard'" :class="$style.arrow"></span>
        <strong>{{activeItem.title}}</strong>
      </span>
    </div>
  </div>
</template>

<script>
import { getLeftMenuData, getTopMenuData } from '@/services/menu'
import { reduce } from 'lodash'

export default {
  name: 'breadcrumbs',
  data() {
    return {
      breadcrumb: [],
      activeItem: {},
      path: [],
    }
  },
  props: {
    settings: Object,
  },
  computed: {
    menuData() {
      return this.settings.isMenuTop ? getTopMenuData : getLeftMenuData
    },
    reverseItems() {
      return this.breadcrumb.slice().reverse()
    },
  },
  methods: {
    getPath(data, url, parents = []) {
      const items = reduce(
        data,
        (result, entry) => {
          if (result.length) {
            return result
          }
          if (entry.children) {
            if (entry.url === url) {
              return [entry].concat(parents)
            } else {
              const nested = this.getPath(entry.children, url, [entry].concat(parents))
              return (result || []).concat(nested.filter(e => !!e))
            }
          }
          if (entry.url !== undefined && entry.url.includes('*') && entry.url.replace('*', url.substr(entry.url.indexOf('*'))) === url) {
            return [entry].concat(parents)
          } else if (entry.url === url) {
            return [entry].concat(parents)
          }
          return result
        },
        [],
      )
      this.activeItem = items[0]
      return items
    },
  },
  mounted: function () {
    this.breadcrumb = this.getPath(this.menuData, this.$route.path)
  },
  watch: {
    $route(to) {
      this.breadcrumb = this.getPath(this.menuData, to.path)
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
