import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
    detail: null,
    parent: null,
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    DELETE(state, items) {},
    SET_CURRENT_DETAIL(state, item) {
      state.detail = item
    },
    SET_CURRENT_PARENT(state, item) {
      state.parent = item
    },
  },
  actions: {
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/comment', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { cmt_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          error = dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/comment/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          error = dealWithErrors(error)
          reject(error)
        })
      })
    },
    getByParent({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/comment/parent/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_PARENT', response.data)
          resolve(response)
        }).catch(error => {
          error = dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/comment/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          error = dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/comment', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          dealWithSuccess({ message: 'OK!', description: 'Přidání proběhlo v pořádku.' })
        }).catch(error => {
          error = dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    items: state => state.items,
    getDetail: state => state.detail,
    getParent: state => state.parent,
  },
}
