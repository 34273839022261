<template>
  <div>
    <div :class="$style.logo">
      <div :class="$style.logoContainer">
        <router-link to="/">
          <img v-lazy="$companyLogoPath" alt="">
        </router-link>
      </div>
    </div>
    <a-menu
      :theme="settings.isLightTheme ? 'light' : 'dark'"
      :mode="'horizontal'"
      :selectedKeys="selectedKeys"
      @click="handleClick"
    >
      <template v-for="item in menuData">
        <item
          v-if="!item.children && !item.divider && !item.hidden"
          :menu-info="item"
          :styles="$style"
          :key="item.key"
        />
        <sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key"/>
      </template>
      <a-menu-item :key="'company'">
        <span>
          <span :class="$style.title">Nastavení firemních údajů</span>
          <ion-icon name="cog-outline" :class="[$style.icon]"></ion-icon>
        </span>
      </a-menu-item>
      <a-menu-item :key="'settings'">
        <span>
          <span :class="$style.title">Nastavení šablony</span>
          <ion-icon name="color-palette-outline" :class="[$style.icon]"></ion-icon>
        </span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import store from 'store'
import _ from 'lodash'
import { getTopMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-top',
  components: { SubMenu, Item },
  props: {
    settings: Object,
  },
  mounted() {
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
  },
  data() {
    return {
      menuData: getTopMenuData,
      selectedKeys: [],
      openKeys: [],
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    '$route'() {
      this.setSelectedKeys()
    },
  },
  methods: {
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = _.find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
