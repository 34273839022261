import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    attributes: [],
  },
  mutations: {
    SET_ATTRIBUTES(state, items) {
      state.attributes = items
    },
  },
  actions: {
    addParcels({ commit }, { orderId, data }) {
      return new Promise((resolve, reject) => {
        $axios.post('/orders/' + orderId + '/balikobot-parcels/add', data, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          store.commit('order/SET_DETAIL_BALIKOBOT_PARCELS', response.data)
          dealWithSuccess({ message: 'OK!', description: 'Přidání proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    printLabels({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        $axios.get('/orders/' + orderId + '/balikobot-parcels', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          dealWithSuccess({ message: 'OK!', description: 'Tisk štítků bylo zaznamenáno.' })
          store.commit('order/ADD_BALIKOBOT_HISTORY', response.data)
          store.commit('order/SET_BALIKOBOT_PARCEL_DETAIL', { key: 'printed_labels_at', data: response.data.created_at })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    orderShipment({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        $axios.post('/orders/' + orderId + '/balikobot-parcels', null, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          dealWithSuccess({ message: 'OK!', description: 'Předání ke svozu proběhlo v pořádku.' })
          store.commit('order/ADD_BALIKOBOT_HISTORY', response.data)
          store.commit('order/SET_BALIKOBOT_PARCEL_DETAIL', { key: 'ordered_shipment_at', data: response.data.created_at })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getAttributes({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        $axios.get('/orders/' + orderId + '/balikobot-parcels-attributes', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ATTRIBUTES', response.data.attributes)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    dropParcels({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        $axios.delete('/orders/' + orderId + '/balikobot-parcels', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          resolve(response)
          store.commit('order/DROP_BALIKOBOT_ITEMS')
          store.commit('order/ADD_BALIKOBOT_HISTORY', response.data)
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    attributes: (state) => state.attributes,
  },
}
