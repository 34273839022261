import Vue from 'vue'

function searchKeys(array, strPath, filteredItems, itemValue, searchText, keyArray = {}) {
  array.some((keyValue) => {
    const strFullPath = strPath + keyValue.key
    let currentItem
    try {
      currentItem = eval(strFullPath)
    } catch (e) {
      return
    }
    if (currentItem === null) {
      currentItem = ''
    } else if (keyValue.type === 'date') {
      currentItem = Vue.filter('formatDate')(currentItem).toString()
    } else if (keyValue.type === 'bool') {
      currentItem = currentItem ? 'ano' : 'ne'
    } else if (keyValue.hashArray !== undefined) {
      currentItem = keyValue.hashArray.find(x => x.status === currentItem).text
    } else {
      currentItem = currentItem + ''.toString()
    }
    if (filteredItems.indexOf(itemValue) === -1 && currentItem.toLowerCase().includes(searchText.toLowerCase())) {
      filteredItems.push(itemValue)
      return true
    }
  })
  return filteredItems
}

export default {
  name: 'filter',
  methods: {
    filterItems: (items, filteredKeys, searchText) => {
      let filteredItems = []
      items.forEach((itemValue) => {
        filteredKeys.some((keyPathValue) => {
          const filteredItemsLength = filteredItems.length
          if (keyPathValue.loop) {
            if (itemValue[keyPathValue.path] !== undefined) {
              itemValue[keyPathValue.path].some(keyArray => {
                filteredItems = searchKeys(keyPathValue.keys, 'keyArray.', filteredItems, itemValue, searchText, keyArray)
              })
            }
          } else {
            filteredItems = searchKeys(keyPathValue.keys, 'itemValue.' + keyPathValue.path, filteredItems, itemValue, searchText)
          }
          if (filteredItemsLength !== filteredItems.length) {
            return true
          }
        })
      })
      return filteredItems
    },
  },
}
