import { $axios, dealWithErrors } from '../import/http'
import store from '@/store'

const perPage = 10

export default {
  namespaced: true,
  state: {
    items: {
      data: [],
      total: 0,
      unread_count: 0,
    },
    settings: [
      {
        type: 'SVY',
        icon: ['stats-chart-outline'],
        message: 'vyplnil dotazník',
      },
      {
        type: 'UER',
        icon: ['person-add-outline'],
        message: 'se zaregistroval',
      },
      {
        type: 'RVC',
        icon: ['star-outline'],
        message: 'přidal novou recenzi',
      },
      {
        type: 'RVU',
        icon: ['star-outline'],
        message: 'upravil svou recenzi',
      },
      {
        type: 'ODR',
        icon: ['cart-outline'],
        message: 'vytvořil novou objednávku',
      },
      {
        type: 'OCD',
        icon: ['cart-outline'],
        message: 'stornoval svou objednávku',
      },
      {
        type: 'CMC',
        icon: ['chatbubble-ellipses-outline'],
        message: 'přidal nový komentář',
      },
      {
        type: 'CMU',
        icon: ['chatbubble-ellipses-outline'],
        message: 'upravil svůj komentář',
      },
      {
        type: 'OCH',
        icon: ['cart-outline'],
        message: 'upravil svou objednávku',
      },
      {
        type: 'CPR',
        icon: 'payment-failure.svg',
        iconType: 'myIcon',
        message: 'zrušil platbu objednávky',
      },
      {
        type: 'PAD',
        icon: 'executed-payment.svg',
        iconType: 'myIcon',
        message: 'zaplatil svou objednávku',
      },
      {
        type: 'PTY',
        icon: 'update-payment.svg',
        iconType: 'myIcon',
        message: 'částečně uhrazena objednávka',
      },
      {
        type: 'OVR',
        icon: 'update-payment.svg',
        iconType: 'myIcon',
        message: 'přeplatek objednávky',
      },
    ],
  },
  mutations: {
    SET_ITEMS(state, items) {
      items.data.forEach((value) => {
        value.settings = state.settings.find(x => x.type === value.ntn_type)
      })
      if (Array.isArray(state.items.data)) {
        items.data = state.items.data.concat(items.data)
      }
      Object.keys(items).forEach(x => {
        state.items[x] = items[x]
      })
    },
    ADD_COUNT(state, amount) {
      state.items.unread_count === undefined ? state.items.unread_count = amount : state.items.unread_count += amount
    },
    SET_READ(state, items) {
      if (items.length === 0) {
        state.items.unread_count = 0
        state.items.data.forEach((value) => {
          value.is_seen_count = 0
        })
      } else {
        let match = 0
        state.items.data.some((value) => {
          if (items.includes(value.ntn_id)) {
            value.is_seen_count = 0
            match += 1
          }
          if (match === items.length) {
            return true
          }
        })
        state.items.unread_count -= match
      }
    },
    SET_UNREAD(state, id) {
      state.items.unread_count += 1
      state.items.data.some((value) => {
        if (id === value.ntn_id) {
          value.is_seen_count = 1
          return true
        }
      })
    },
  },
  actions: {
    getList({ commit }, page) {
      return new Promise((resolve, reject) => {
        $axios.get('/notification?page=' + page, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getCount({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/notification/count', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    patch({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.patch('/notification/' + id, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SET_UNREAD', id)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/notification', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { ntn_id: x } }) },
        }).then(() => {
          commit('SET_READ', items)
          resolve()
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    getItems: (state) => {
      return { count: state.items.total, data: state.items.data === undefined ? [] : state.items.data, per_page: perPage }
    },
    getCount: state => state.items.unread_count === undefined ? 0 : state.items.unread_count,
    getSettings: state => state.settings,
  },
}
