<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
      <SWUpdate></SWUpdate>
    </div>
  </a-config-provider>
</template>

<script>
import csCZ from 'ant-design-vue/lib/locale-provider/cs_CZ'
import SWUpdate from '@/components/MyComponents/SWUpdate/SWUpdate'
// import broadcast from '@/services/broadcast'
export default {
  name: 'app',
 // mixins: [broadcast],
  components: { SWUpdate },
  data() {
    return {
      locale: csCZ,
    }
  },
  computed: {
    access_token() {
      return this.$store.getters.access_token
    },
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
  },
  watch: {
    access_token(auth) {
      if (auth) {
        this.$router.replace(this.nextRoute)
      }
    },
    '$route'(to) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
}
</script>
