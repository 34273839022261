import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {},
  },
  mutations: {
  },
  actions: {
    post({ commit }, { userId, item }) {
      return new Promise((resolve, reject) => {
        $axios.post('/users/' + userId + '/user-notes', { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          resolve(response.data)
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { userId, userNotesId, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/users/' + userId + '/user-notes/' + userNotesId, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          resolve(response.data)
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, { userId, userNotesId }) {
      return new Promise((resolve, reject) => {
        $axios.delete('/users/' + userId + '/user-notes/' + userNotesId, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
  },
}
