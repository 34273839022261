import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'prr_id',
        type: 'int',
      },
      {
        key: 'prr_is_numeric',
        type: 'bool',
      },
    ],
  },
  {
    path: 'languages[0].pivot.',
    loop: false,
    keys: [
      {
        key: 'ple_name',
        type: 'string',
      },
      {
        key: 'ple_unit',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {
      languages: [],
    },
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENT(state, items) {
      const index = state.items.indexOf(state.items.find(x => x.lge_id === store.getters['language/active']))
      if (index !== -1) {
        state.items.splice(index, 1)
      }
      state.items.push(items)
    },
    SET_CURRENT_DETAIL(state, item) {
      const index = state.detail.languages.indexOf(state.detail.languages.find(x => x.lge_id === store.getters['language/active']))
      const oldLang = state.detail.languages
      state.detail = Object.assign({}, item)
      state.detail.languages = oldLang
      if (index !== -1) {
        state.detail.languages.splice(index, 1)
      }
      state.detail.languages.push({
        lge_id: store.getters['language/active'],
        ple_name: item.languages.length > 0 ? item.languages[0].pivot.ple_name : undefined,
        ple_unit: item.languages.length > 0 ? item.languages[0].pivot.ple_unit : undefined,
      })
      state.detail.values = item.values.map(x => x.vue_id.toString())
    },
    DELETE(state, items) {
      state.items.forEach((value, key) => {
        for (let i = 0; i < value.data.length; i++) {
          if (items.includes(value.data[i].prr_id)) {
            state.items[key].data.splice(i, 1)
            i = i - 1
          }
        }
      })
    },
  },
  actions: {
    getList({ commit }, expand = null) {
      return new Promise((resolve, reject) => {
        let url = '/parameter?lge_id=' + store.getters['language/active']
        if (expand !== null) {
          url += '&expand=' + expand
        }
        $axios.get(url, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/parameter/' + id + '/lang/' + store.getters['language/active'], {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.delete('/parameter', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: items.map(x => { return { prr_id: x } }) },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putSort({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.put('/parameter', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('SET_ITEMS', [])
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Seřazení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/parameter/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/parameter', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    currentLanguage: (state) => (searchText = '') => {
      const activeItems = state.items.filter(x => x.lge_id === store.getters['language/active'])
      const currentItems = store.getters['language/active'] !== null && activeItems.length !== 0 && activeItems[0].data !== undefined ? activeItems[0].data : []
      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    currentLanguageDetail: (state) => {
      return state.detail.languages !== undefined ? state.detail.languages.find(x => x.lge_id === store.getters['language/active']) : undefined
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
