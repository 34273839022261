import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import moment from 'moment'

const statuses = [{
  status: 'SET',
  text: 'odeslaná',
  color: 'orange',
}, {
  status: 'RCD',
  text: 'přijatá',
  color: 'blue',
}, {
  status: 'RSN',
  text: 'připravuje se k odeslání',
  color: 'black',
}, {
  status: 'RFP',
  text: 'připravena k vyzvednutí',
  color: 'cyan',
}, {
  status: 'CCD',
  text: 'storno',
  color: 'red',
}, {
  status: 'PIG',
  text: 'vyřizuje se',
  color: 'purple',
}, {
  status: 'CPD',
  text: 'vyřízená',
  color: 'green',
}]

const notifierTypes = [
  {
    name: 'SMS',
    value: 'sms',
  }, {
    name: 'EMAIL',
    value: 'e-mail',
  },
]

const notifierFlags = [
  {
    name: 'WITH_TRACK_LINK',
    value: 's odkazem pro sledování zásilky',
  },
]

const notifiers = [
  {
    name: 'ORDER_CHANGE_STATUS',
    value: 'změna stavu objednávky',
  }, {
    name: 'ORDER_CREATED',
    value: 'přijali jsme objednávku',
  },
]

const trackStatuses = [{
  status: 'ORDERED',
  text: 'Objednáno',
  color: 'yellow',
}, {
  status: 'PICKED_UP_FROM_SENDER',
  text: 'Vyzvednuta u odesilatele',
  color: 'purple',
}, {
  status: 'TRANSIT',
  text: 'Transit',
  color: 'purple',
}, {
  status: 'READY_TO_PICK_UP',
  text: 'Připravena k vyzvednutí',
  color: 'blue',
}, {
  status: 'SEND_BACK_TO_SENDER',
  text: 'Zpět na cestě k odesilateli',
  color: 'purple',
}, {
  status: 'HANDED_TO_FINAL_SHIPPER',
  text: 'Předána koncovému dopravci',
  color: 'purple',
}, {
  status: 'CANCELLATION_BY_SHIPPER',
  text: 'Storno ze strany dopravce',
  color: 'volcano',
}, {
  status: 'CANCELLATION_BY_RECIPIENT',
  text: 'Storno ze strany příjemce',
  color: 'red',
}, {
  status: 'CANCELLATION_BY_SENDER',
  text: 'Storno ze strany odesilatele',
  color: 'pink',
}, {
  status: 'DELIVERED_BACK_TO_SENDER',
  text: 'Doručena zpět odesilateli',
  color: 'orange',
}, {
  status: 'COD_PAID',
  text: 'Dobírka byla připsána na účet odesilatele',
  color: 'lime',
}, {
  status: 'ERROR_SHIPPER',
  text: 'Chyba u dopravce',
  color: 'red',
}, {
  status: 'ERROR_RECIPIENT',
  text: 'Chyba na straně příjemce',
  color: 'red',
}, {
  status: 'ERROR_SENDER',
  text: 'Chyba na straně odesilatele',
  color: 'red',
}, {
  status: 'PICKUP_ON_DELIVERY_POINT',
  text: 'Vyzvednutí zásilky na výdejním místě',
  color: 'blue',
}, {
  status: 'DELIVERED_TO_ADDRESS',
  text: 'Doručeno na adresu',
  color: 'green',
}]

const paymentStatuses = [{
  status: 'REFUNDED',
  text: 'refundováno',
  color: 'green',
}, {
  status: 'PAID',
  text: 'zaplaceno',
  color: 'green',
}, {
  status: 'CANCELLED',
  text: 'storno',
  color: 'red',
}, {
  status: 'PENDING',
  text: 'čeká na zaplacení',
  color: 'blue',
}, {
  status: 'AUTHORIZED',
  text: 'autorizována',
  color: 'green',
}, {
  status: 'PARTIALLY',
  text: 'částečně uhrazena',
  color: 'orange',
}, {
  status: 'OVERPAID',
  text: 'přeplatek',
  color: 'orange',
}]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: null,
    statuses: statuses,
    track_statuses: trackStatuses,
    payment_statuses: paymentStatuses,
    notifier_types: notifierTypes,
    notifiers: notifiers,
    notifier_flags: notifierFlags,
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_DETAIL_INFO(state, { key, data }) {
      state.detail[key] = data
    },
    DROP_BALIKOBOT_ITEMS(state) {
      state.detail.balikobot_parcels.items = []
    },
    SET_BALIKOBOT_PARCEL_DETAIL(state, { key, data }) {
      state.detail.balikobot_parcels.items.forEach(x => {
        x[key] = data
      })
      state.detail.balikobot_parcels.items = Array.from(state.detail.balikobot_parcels.items)
    },
    SET_DETAIL_BALIKOBOT_PARCELS(state, data) {
      if (state.detail !== null && Object.keys(state.detail).includes('balikobot_parcels') && Array.isArray(state.detail.balikobot_parcels.histories)) {
        state.detail.balikobot_parcels.items = data.items
        state.detail.balikobot_parcels.histories.push(data.histories)
      } else {
        state.detail.balikobot_parcels = data
      }
    },
    SET_CURRENT(state, items) {
      state.items = items.data
    },
    ADD_BALIKOBOT_HISTORY(state, item) {
      state.detail.balikobot_parcels.histories.push(item)
    },
    SET_CURRENT_DETAIL(state, item) {
      state.detail = item
    },
    ADD_NOTE(state, { id, item }) {
      item.created_at = moment()
      item.updated_at = moment()
      item.uer_id = store.getters['auth/getItem'].uer_id
      item.one_id = id
      state.detail.notes.push(item)
    },
    UPDATE_NOTE(state, { id, item }) {
      item.updated_at = moment()
      item.uer_id = store.getters['auth/getItem'].uer_id
      const oldNote = state.detail.notes.find(x => x.one_id === id)
      Object.assign(oldNote, item)
    },
    UPDATE_DELIVERY_PAYMENT(state, data) {
      Array.of('payment_total', 'delivery_total').forEach(x => {
        if (data[x] !== undefined) {
          const diff = state.detail.order_info[x] - data[x]
          state.detail.order_info.order_total_vat -= diff
          state.detail.order_info.order_total_vat_rounded = Math.ceil(state.detail.order_info.order_total_vat)
          state.detail.order_info.order_total_no_vat -= diff - (diff * 0.1736)
          state.detail.order_info[x] = data[x]
        }
      })

      Array.of('pty_id', 'dhy_id').forEach(x => {
        if (data[x]) {
          state.detail.order_info[x] = data[x]
        }
      })
    },
    UPDATE_CONTACT(state, data) {
      state.detail.order_info.contact_id = data.id
      state.detail.order_info.contact = {
        id: data.id,
        email: data.email,
        telephone_number: data.telephone_number,
        phone_code_id: data.phone_code_id,
        phone_code: data.phone_code,
      }
    },
    UPDATE_COMPANY(state, data) {
      if (data === null) {
        state.detail.order_info.company_id = null
        state.detail.order_info.company = null
      } else {
        state.detail.order_info.company_id = data.id
        state.detail.order_info.company = {
          id: data.id,
          name: data.name,
          tin: data.tin,
          vatin: data.vatin,
        }
      }
    },
    UPDATE_ORDER_ITEM(state, { id, item, quantity }) {
      const oldNote = state.detail.items.find(x => x.oim_id === item.oim_id)
      item.quantity = quantity
      Object.assign(oldNote, item)
    },
    DELETE_NOTE(state, item) {
      state.detail.notes.splice(state.detail.notes.indexOf(item), 1)
    },
    DELETE_ITEM(state, items) {
      for (let i = 0; i < state.detail.items.length; i++) {
        if (items.includes(state.detail.items[i].oim_id)) {
          state.detail.items.splice(i, 1)
          i = i - 1
        }
      }
    },
    DELETE(state, items) {
      for (let i = 0; i < state.items.length; i++) {
        if (items.includes(state.items[i].odr_id)) {
          state.items[i].odr_is_deleted = true
        }
      }
    },
    UPDATE_STATUS(state, payload) {
      for (let i = 0; i < state.items.length; i++) {
        if (payload.items.includes(state.items[i].odr_id)) {
          state.items[i].status = payload.status
        }
      }
      if (state.detail !== null && payload.items.includes(state.detail.order_info.odr_id)) {
        state.detail.order_info.status = payload.status
      }
    },
    UPDATE_PAYMENT_STATUS(state, payload) {
      for (let i = 0; i < state.items.length; i++) {
        if (payload.items.includes(state.items[i].odr_id)) {
          state.items[i].payment_status = payload.payment_status
        }
      }
      if (state.detail !== null && payload.items.includes(state.detail.order_info.odr_id)) {
        state.detail.order_info.payment_status = payload.payment_status
      }
    },
    UPDATE(state, payload) {
      Object.keys(payload).forEach(x => {
        state.detail.order_info[x] = payload[x]
      })
    },
    UPDATE_BILLING_ADDRESS(state, payload) {
      state.detail.order_info.billing_address_id = payload.id
      state.detail.order_info.billing_address = {
        id: payload.id,
        name: payload.name,
        street: payload.street,
        city: payload.city,
        zip: payload.zip,
        country_id: payload.country_id,
      }
    },
    UPDATE_DELIVERY_ADDRESS(state, payload) {
      state.detail.order_info.delivery_address_id = payload.id
      state.detail.order_info.delivery_address = {
        id: payload.id,
        name: payload.name,
        street: payload.street,
        city: payload.city,
        zip: payload.zip,
        country_id: payload.country_id,
      }
    },
  },
  actions: {
    getList({ commit }, query = '') {
      return new Promise((resolve, reject) => {
        $axios.get('/order' + query, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT', response.data)
          resolve(response.data)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/order/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_CURRENT_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putDeliveryPayment({ commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        $axios.put('/order/' + id + '/delivery-payment', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('UPDATE_DELIVERY_PAYMENT', data)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putDeliveryAddress({ commit }, { orderId, addressId, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/orders/' + orderId + '/delivery-addresses/' + addressId, item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('UPDATE_DELIVERY_ADDRESS', response.data.data.delivery_address)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putBillingAddress({ commit }, { orderId, addressId, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/orders/' + orderId + '/billing-addresses/' + addressId, item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('UPDATE_BILLING_ADDRESS', response.data.data.billing_address)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    put({ commit }, { orderId, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/orders/' + orderId, item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('UPDATE', item)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putContact({ commit }, { orderId, contactId, data }) {
      return new Promise((resolve, reject) => {
        $axios.put('/orders/' + orderId + '/contacts/' + contactId, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('UPDATE_CONTACT', response.data.data.contact)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putCompany({ commit }, { orderId, companyId, data }) {
      return new Promise((resolve, reject) => {
        $axios.put('/orders/' + orderId + '/companies/' + companyId, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('UPDATE_COMPANY', response.data.data.company)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    status({ commit }, object) {
      return new Promise((resolve, reject) => {
        $axios.patch('/order?' + (object.status ? 'status=' + object.status : 'payment_status=' + object.payment_status), {
          data: object.all === true ? 'ALL' : object.items.map(x => {
            return { odr_id: x }
          }),
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          if (object.status) {
            commit('UPDATE_STATUS', { items: object.items, status: object.status })
          } else {
            commit('UPDATE_PAYMENT_STATUS', { items: object.items, payment_status: object.payment_status })
          }
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení stavu proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, { items, all }) {
      return new Promise((resolve, reject) => {
        $axios.delete('/order', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: {
            data: all === true ? 'ALL' : items.map(x => {
              return { odr_id: x }
            }),
          },
        }).then(() => {
          commit('DELETE', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    addBalikobotParcel({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.post('/orders/' + id + '/balikobot-parcels', null, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((data) => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Přidání balíku proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    postItem({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.post('/order/' + id + '/item', { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((data) => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putItem({ commit }, { id, item, data }) {
      return new Promise((resolve, reject) => {
        $axios.put('/order/' + id + '/item/' + item.oim_id, {
          data: data,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        },
        ).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    deleteItem({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.delete('/order/' + id + '/item/' + item, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    postNote({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/order/note', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((data) => {
          commit('ADD_NOTE', { id: data.data, item: items })
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putNote({ commit }, { id, item }) {
      return new Promise((resolve, reject) => {
        $axios.put('/order/note/' + id, { data: item }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('UPDATE_NOTE', { id: id, item: item })
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    deleteNote({ commit }, item) {
      return new Promise((resolve, reject) => {
        $axios.delete('/order/note/' + item.one_id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('DELETE_NOTE', item)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Odstranění proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filterItems: (state) => {
      return state.items
    },
    getDetail: (state) => {
      return state.detail
    },
    getStatuses: (state) => {
      return state.statuses
    },
    getTrackStatuses: (state) => {
      return state.track_statuses
    },
    getNotifiers: (state) => {
      return state.notifiers
    },
    getNotifierFlags: (state) => {
      return state.notifier_flags
    },
    getNotifierTypes: (state) => {
      return state.notifier_types
    },
    getPaymentStatuses: (state) => {
      return state.payment_statuses
    },
  },
}
