import { $axios, dealWithErrors, dealWithSuccess } from '../import/http'
import store from '@/store'
import filter from '../import/filter'
import moment from 'moment'

const filteredKeys = [
  {
    path: '',
    loop: false,
    keys: [
      {
        key: 'uer_id',
        type: 'int',
      },
      {
        key: 'email',
        type: 'string',
      },
      {
        key: 'uer_is_active',
        type: 'bool',
      },
      {
        key: 'banned_to',
        type: 'date',
      },
      {
        key: 'created_at',
        type: 'date',
      },
      {
        key: 'public_group.grp_name',
        type: 'string',
      },
    ],
  },
]

export default {
  namespaced: true,
  state: {
    items: [],
    detail: {},
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_DETAIL(state, item) {
      state.detail = item
    },
    DELETE(state, payload) {
      if (state.items.data === undefined) {
        return false
      }
      for (let i = 0; i < state.items.data.length; i++) {
        if (payload.items.includes(state.items.data[i].uer_id)) {
          state.items.data[i].banned_from = moment()
          state.items.data[i].banned_to = moment().add(payload.days, 'days')
        }
      }
    },
    BAN_PAYLOAD(state, payload) {
      payload.models.forEach(value => {
        value.user.banned_to = moment().add(payload.days, 'days')
      })
    },
    BAN_DETAIL(state) {
      state.detail.banned_to = true
    },
    REVERT(state, items) {
      if (state.items.data === undefined) {
        return false
      }
      for (let i = 0; i < state.items.data.length; i++) {
        if (items.includes(state.items.data[i].uer_id)) {
          state.items.data[i].banned_from = null
          state.items.data[i].banned_to = null
        }
      }
    },
    UPDATE_GROUP(state, payload) {
      if (state.items.data === undefined) {
        return false
      }
      for (let i = 0; i < state.items.data.length; i++) {
        if (payload.items.includes(state.items.data[i].uer_id)) {
          state.items.data[i].public_group.grp_name = payload.group.grpName
          state.items.data[i].public_group.grp_id = payload.group.grpId
        }
      }
    },
    UPDATE_USER_NOTE(state, { userNotesId, item }) {
      state.detail.user_notes.some((val, key) => {
        if (val.id === userNotesId) {
          state.detail.user_notes.splice(key, 1)
          state.detail.user_notes.splice(key, 0, item)
          return true
        }
      })
    },
    ADD_USER_NOTE(state, item) {
      state.detail.user_notes.push(item)
    },
    DELETE_USER_NOTE(state, id) {
      state.detail.user_notes.some((val, key) => {
        if (val.id === id) {
          state.detail.user_notes.splice(key, 1)
          return true
        }
      })
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        $axios.get('/user/registered', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    getOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        $axios.get('/user/registered/' + id, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(response => {
          commit('SET_DETAIL', response.data)
          resolve(response)
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    post({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.post('/user/registered', { data: items }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Vytvoření proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    putGroupDiscounts({ commit }, { userId, groupId, data }) {
      return new Promise((resolve, reject) => {
        $axios.put('/user/registered/' + userId + '/discounts/' + groupId, { data: data }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Upravení proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    delete({ commit }, payload) {
      return new Promise((resolve, reject) => {
        $axios.delete('/user/registered/' + payload.days, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
          data: { data: payload.items.map(x => { return { uer_id: x } }) },
        }).then(() => {
          commit('DELETE', payload)
          if (payload.models !== undefined) {
            commit('BAN_PAYLOAD', payload)
          }
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Zabanovat proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    revert({ commit }, items) {
      return new Promise((resolve, reject) => {
        $axios.patch('/user/registered/revert', {
          data: items.map(x => { return { uer_id: x } }),
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then((response) => {
          commit('REVERT', items)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Zrušení zákazu proběhlo v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
    patch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        $axios.patch('/user/registered/group/' + payload.group.grpId, {
          data: payload.items.map(x => { return { uer_id: x } }),
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + store.state.token.access_token,
          },
        }).then(() => {
          commit('UPDATE_GROUP', payload)
          resolve()
          dealWithSuccess({ message: 'OK!', description: 'Změna skupiny proběhla v pořádku.' })
        }).catch(error => {
          dealWithErrors(error)
          reject(error)
        })
      })
    },
  },
  getters: {
    allItems: state => state.items,
    filteredItems: (state) => (searchText = '') => {
      const currentItems = state.items.length !== 0 && state.items.data !== undefined ? state.items.data : []
      if (searchText.trim().length > 0) {
        return filter.methods.filterItems(currentItems, filteredKeys, searchText)
      }
      return currentItems
    },
    getDetail: (state) => {
      return state.detail
    },
  },
}
